import axios from 'axios';
import store from '@/store';

const baseCore = axios.create({
  baseURL: `${process.env.VUE_APP_CORE_BASE_URL}`,
  headers: {
    'Content-Type': 'application/json',
    'X-gprim-specnav': window.navigator.userAgent,
    'X-gprim-specdev': 'WEB',
    'X-gprim-specver': '2.2.1',
  },
});

baseCore.interceptors.request.use(
  function (config) {
    const token = store.getters['auth/token'];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

baseCore.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (err) => {
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      await store.dispatch('currentLogout');
    }

    return Promise.reject(err);
  }
);

export default baseCore;
