import { utils as utilsMixins } from '@/mixins/utils';

const LIGHT_CHECKOUT_STEP_KEY = '[DNT]light_checkout_';

export const EVENTS_MAPPING = {
  ONBOARDING: {
    name: 'onboarding',
    actions: {
      ANSWERED: 'answered',
      ANSWER_LATER: 'answerLater',
      SKIP_QUESTION: 'skipQuestion',
      STUDY_PLAN_OPENED: 'studyPlanOpened',
      REDO_PROFILE: 'redoProfile',
    },
  },
  LIFETIME: {
    name: 'lifetime',
    actions: {
      PIX_GENERATED: 'pixGenerated',
      PIX_PAID: 'pixPaid',
      PAY_WITH: 'payWith',
    },
    labels: {
      NEW: 'new',
      RETRY: 'retry',
      PIX_OR_TICKET: 'Pix/Boleto',
    },
  },
  SPITI_ONBOARDING: {
    name: 'spitiOnboarding',
    actions: {
      SHOW_ME_LATER: 'showMeLater',
      KNOW_MORE: 'knowMore',
      CONNECT_NOW: 'connectNow',
      LOGIN: 'login',
      CREATE_ACCOUNT: 'createAccount',
      FORGOT_PASSWORD: 'forgotPassword',
      CREATE_SUCCESSFULLY: 'createSuccessfully',
      FORGOT_EMAIL_SUCCESSFULLY: 'forgotEmailSuccessfully',
    },
  },
  SPITI_HOME: {
    name: 'spitiHome',
    actions: {
      AVAILABLE_CLICK: 'availableClick',
      UNAVAILABLE_CLICK: 'unavailableClick',
      CONNECT_BANNER: 'connectBanner',
    },
  },
  SPITI_SERIES: {
    name: 'spitiSeries',
    actions: {
      REPORT_CLICK: 'reportClick',
      SHOW_RECOMMENDED: 'showRecommended',
      REPORT_SEARCH: 'reportSearch',
      VIDEO_CLICK: 'videoClick',
      VIDEO_SEARCH: 'videoSearch',
      LIVE_CLICK: 'liveClick',
      LIVE_SEARCH: 'liveSearch',
    },
  },
  SPITI_INTEGRATION: {
    name: 'spitiIntegration',
    actions: {
      DISCONNECT_CLICK: 'disconnectClick',
      CONNECT_CLICK: 'connectClick',
      DISCONNECT_SUCCESSFULLY: 'disconnectSuccessfully',
    },
  },
  TRAIL: {
    name: 'trail',
    actions: {
      SELECTED_TRAIL: 'selectedTrail',
      SELECTED_COURSE: 'selectedCouse',
      SELECTED_COURSE_BANNER: 'selectedCouseBanner',
    },
  },
};

// NOTE: this function replicates methods of file "@/mixins/dataLayerMixin"
export default function useDatalayer() {
  function savePlatformPurchase(tid, email, offer) {
    push('purchase', {
      ecommerce: {
        purchase: {
          actionField: {
            coupon: offer?.coupon || '',
            revenue: offer.price,
            id: String(tid) || String(Math.floor(100000 + Math.random() * 900000)),
          },
          products: [
            {
              name: offer.name,
              id: offer.slug,
              price: offer.price,
              brand: 'Finclass',
              category: 'Full Acesso',
              variant: offer.trial > 0 ? 'trial' : 'normal',
              quantity: 1,
            },
          ],
        },
      },
      email,
      paymentInstallments: offer.installment,
      checkoutType: 'LIGHT_CHECKOUT',
    });
  }

  function createLightCheckoutEvent(step, send = true) {
    if (!step) return;

    const ecommerceProps = {
      ecommerce: { checkout: { actionField: { step } } },
    };

    if (send) {
      sendLightCheckoutEvent(ecommerceProps);
    } else {
      return ecommerceProps;
    }
  }

  function sendLightCheckoutEvent(ecommerceProps) {
    const currentStep = ecommerceProps.ecommerce?.checkout?.actionField?.step;
    const key = `${LIGHT_CHECKOUT_STEP_KEY}${currentStep}`;
    const stepAlreadyStored = window.sessionStorage.getItem(key);

    if (!stepAlreadyStored) {
      push('checkout', {
        ...ecommerceProps,
        checkoutType: 'LIGHT_CHECKOUT',
      });

      window.sessionStorage.setItem(key, true);
    }
  }

  function push(event = '', customProps = {}) {
    // Clear the previous ecommerce object.
    if (typeof customProps === 'object') {
      const keys = Object.keys(customProps);
      if (keys.includes('ecommerce')) {
        window.dataLayer.push({ ecommerce: null });
      }
    }

    window.dataLayer.push({ event, ...customProps });
  }

  return { createLightCheckoutEvent, savePlatformPurchase, sendLightCheckoutEvent, push };
}
