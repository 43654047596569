import { REDIRECT_ROUTE_AFTER_LOGIN_SESSIONSTORAGE_KEY } from '@/Utils/selectsValues';

export const HOME_ROUTE_NAME = 'Home';
const NOT_FOUND_ROUTE_NAME = 'NotFound';

export default function useSessionStorage() {
  function addRedirectAttempt(routeName, routeParams, queryParams = {}) {
    let route = routeName;
    let params = routeParams;
    let query = queryParams;

    if (!routeName || routeName === NOT_FOUND_ROUTE_NAME) route = HOME_ROUTE_NAME;
    if (!queryParams || !Object.keys(queryParams).length) query = {};
    if (!routeParams || !Object.keys(routeParams).length) params = {};

    const attemptData = `${route};${JSON.stringify(params)};${JSON.stringify(query)}`;
    window.sessionStorage.setItem(REDIRECT_ROUTE_AFTER_LOGIN_SESSIONSTORAGE_KEY, attemptData);
  }

  function getRouteToRedirect() {
    const sessionStorageValue = window.sessionStorage.getItem(REDIRECT_ROUTE_AFTER_LOGIN_SESSIONSTORAGE_KEY);
    if (sessionStorageValue) {
      const [name, params, query] = sessionStorageValue.split(';');
      const rawRouteToRedirect = {
        name: name,
        params: params,
        query: query,
      };

      return {
        name: rawRouteToRedirect.name,
        routeParams: JSON.parse(rawRouteToRedirect.params),
        queryParams: JSON.parse(rawRouteToRedirect.query),
      };
    }

    return { name: HOME_ROUTE_NAME, routeParams: {}, queryParams: {} };
  }

  return { addRedirectAttempt, getRouteToRedirect };
}
