<template>
  <div class="menu-mobile">
    <ul @click="toggleMenu" class="gtm-menu">
      <UserProgressPanel class="menu-mobile--spacing" />

      <li v-for="option in navigateLessonsOptions" :key="option.title">
        <router-link :to="{ name: option.name, ...(option.params && { params: option.params }) }">
          {{ option.title }}
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Finclub' }"> Comunidade </router-link>
      </li>
      <li>
        <a href="/carteira/10830e24-6830-40c1-bc23-699b060ec73e"> Carteira </a>
      </li>
      <li>
        <router-link :to="{ name: 'Profile' }"> Meu perfil </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'PersonalData' }"> Dados pessoais </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'MySignature' }" @click="pushRenewalEventToDataLayer">
          Assinatura
          <PointWarning />
        </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'Progress' }"> Meu progresso </router-link>
      </li>
      <li>
        <router-link :to="{ name: 'HelpCenter' }"> Central de ajuda </router-link>
      </li>
      <li @click="doLogout"><a>Desconectar</a></li>
    </ul>
  </div>
</template>

<script>
import { logoutUserMix } from '@/mixins';
import { mapGetters, mapActions } from 'vuex';
import PointWarning from '@/components/PointWarning';
import { dataLayerMixin } from '@/mixins/dataLayerMixin';
import UserProgressPanel from '@/components/onboarding/UserProgressPanel';
import { TOP_BAR_WARNING_IDS, navigateLessonsOptions } from '@/Utils/selectsValues';

const { SIGNATURE, CREDIT_CARD } = TOP_BAR_WARNING_IDS;

export default {
  emits: ['toggleMenu'],
  mixins: [logoutUserMix, dataLayerMixin],
  components: {
    PointWarning,
    UserProgressPanel,
  },
  data() {
    return {
      navigateLessonsOptions,
    };
  },
  computed: {
    ...mapGetters(['pointWarning', 'userIsLifetime']),
    ...mapGetters('courses', ['lastPublishedFinsession']),
    hasPointWarning() {
      return Array.isArray(this.pointWarning) && this.pointWarning.length;
    },
  },
  methods: {
    ...mapActions(['setRenewalFlowType']),
    pushRenewalEventToDataLayer() {
      if (!this.hasPointWarning) return;

      this.setRenewalFlowType('POINT_WARNING');

      return this.pushToDataLayer(this.pointWarningDataLayerKey, {
        flow: 'POINT_WARNING',
        step: 'POINT_WARNING_HEADER_SIGNATURE',
      });
    },
    pointWarningDataLayerKey() {
      if (this.pointWarning.includes(CREDIT_CARD)) {
        return 'changePaymentMethodStart';
      }
      if (this.pointWarning.includes(SIGNATURE)) {
        return 'recurrencyOnStart';
      }
    },
    toggleMenu() {
      this.$emit('toggleMenu');
    },
    doLogout() {
      this.$emit('toggleMenu');
      this.logoutUser();
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-mobile {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000000;
  z-index: 1;
  opacity: 0;
  transform: translateY(100%);
  overflow-y: auto;
  transition: all 0.3s;

  &--spacing {
    margin-bottom: 24px;
  }

  ul {
    margin-top: 70px;
    border-top: 1px solid rgba(32, 34, 39, 0.8);
    border-bottom: 1px solid rgba(32, 34, 39, 0.8);
    padding: 32px 15px;

    li {
      margin-bottom: 2.25rem;

      &:last-child {
        margin-bottom: 0;
      }

      a,
      button {
        display: flex;
        align-items: center;
        position: relative;
        font-weight: 600;
        font-size: 1.25rem;
        line-height: 115%;
        letter-spacing: -0.005em;
        color: #ffffff;
        opacity: 0.7;

        .menu__new-highlight {
          margin-left: 1rem;
          width: 12px;
          height: 12px;
          background-color: $red;
          border-radius: 50%;
          box-shadow: 0 0 0 0 $red;
          transform: scale(1);
          animation: pulse 1s infinite;
        }

        .pointWarning {
          right: 0;
          left: 88px;
        }
      }
    }
  }
  .app {
    padding: 40px 15px;

    h3 {
      font-size: 1.25rem;
      color: $dark-100;
      margin-bottom: 1.5rem;
    }

    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>
