import { defineRule } from 'vee-validate';
import { isValidPhoneNumber } from 'libphonenumber-js/max';
import { MAX_CREDIT_CARD_EXPIRY_YEAR } from '@/Utils/constants/ecommerce';
import { isValidCardNumber, isValidCPF, isValidCNPJ } from '@/Utils/functions';

const MIN_NUMBERS_LENGTH_PHONE = 10;

defineRule('required', (value) => {
  if (!value) {
    return 'Este campo é obrigatório';
  }

  return true;
});

defineRule('email', (value) => {
  if (!/(^[^@][a-z0-9-_.]+)(@{1})([a-z0-9-_]+[^@])(\.{1})([a-z0-9]+[^@])/i.test(value)) {
    return 'Este campo deve possuir um e-mail válido (ex: xxx@xxx.com)';
  }

  return true;
});

defineRule('phone', (value, [country = 'BR']) => {
  if (!value || !value.length) {
    return true;
  }

  const onlyPhoneNumbers = value.replace(/\D/g, '');
  if (onlyPhoneNumbers.length < MIN_NUMBERS_LENGTH_PHONE) {
    return `O campo deve ter, ao menos, ${MIN_NUMBERS_LENGTH_PHONE} caracteres`;
  }

  if (!isValidPhoneNumber(value, country)) {
    return 'Número de telefone inválido';
  }

  return true;
});

defineRule('min', (value, [limit]) => {
  if (!value || !value.length) {
    return true;
  }

  if (value.length < limit) {
    return `O campo deve ter, ao menos, ${limit} caracteres`;
  }

  return true;
});

defineRule('max', (value, [limit]) => {
  if (!value || !value.length) {
    return true;
  }

  if (value.length > limit) {
    return `O campo deve ter, no máximo, ${limit} caracteres`;
  }

  return true;
});

defineRule('only-letters', (value) => {
  if (!value || !value.length) {
    return true;
  }

  const letter = value.replace(/\D/g, '');
  if (!/^[a-zA-Z]*$/g.test(letter)) {
    return 'Digite apenas letras';
  }

  return true;
});

defineRule('only-numbers', (value) => {
  if (!value || !value.length) {
    return true;
  }

  if (!/^[0-9]*$/g.test(value)) {
    return 'Digite apenas números';
  }

  return true;
});

defineRule('fullname', (value) => {
  if (!value || !value.length) {
    return true;
  }

  if (!/([A-zÀ-ú]{2})+\s([A-zÀ-ú]{1})+/g.test(value)) {
    return 'Insira o nome completo, com sobrenome';
  }

  return true;
});

defineRule('credit-card', (value) => {
  if (!value || !value.length) {
    return true;
  }

  const cleanedValue = value.replace(/[^\d]/g, '');
  if (cleanedValue.length > 14 && isValidCardNumber(cleanedValue)) {
    return true;
  }

  return 'Número de cartão inválido';
});

defineRule('cpf', (value) => {
  if (!value || !value.length) {
    return true;
  }

  const cleanedValue = value.replace(/[^\d]/g, '');
  if (isValidCPF(cleanedValue)) {
    return true;
  }

  return 'Número de CPF inválido';
});

defineRule('cpf-cnpj', (value) => {
  if (!value || !value.length) {
    return true;
  }

  const cleanedValue = value.replace(/[^\d]/g, '');
  if (cleanedValue.length === 11) {
    if (isValidCPF(cleanedValue)) {
      return true;
    }
  } else if (cleanedValue.length === 14) {
    if (isValidCNPJ(cleanedValue)) {
      return true;
    }
  }

  return 'Por favor, revise esse campo.';
});

defineRule('expiration-date', (value, [length = 7]) => {
  if (!value || !value.length) {
    return true;
  }

  const expirationDate = value.replace(/(\d{2})\/?(\d{4})/, '$1/$2');

  if (expirationDate.length < length) {
    return 'Preencha o mês e o ano';
  }

  const currentMonth = new Date().getMonth() + 1;
  const regex = length === 7 ? /(\d{2})\/?(\d{4})/ : /(\d{2})\/?(\d{2})/;
  const currentYear =
    length === 7 ? new Date().getFullYear() : Number(new Date().getFullYear().toString().substring(2));
  const maxCreditCardExpiryYear =
    length === 7 ? MAX_CREDIT_CARD_EXPIRY_YEAR : MAX_CREDIT_CARD_EXPIRY_YEAR.toString().substring(2);

  const inputMonth = Number(value.replace(regex, '$1'));
  const inputYear = Number(value.replace(regex, '$2'));

  if (
    inputYear < currentYear ||
    inputYear > maxCreditCardExpiryYear ||
    (inputYear === currentYear && inputMonth <= currentMonth) ||
    inputMonth > 12
  ) {
    return 'Data inválida.';
  }

  return true;
});

defineRule('cep', (value) => {
  if (!value || !value.length) {
    return true;
  }

  if (!/^[0-9]{5}-[0-9]{3}$/.test(value)) {
    return 'CEP inválido';
  }

  return true;
});
