import baseCore from '@/http/baseCore';

const ENDPOINT = '/dateNow';

const state = {
  dateNow: '',
};

const getters = {
  getDateNow: (state) => new Date(state.dateNow).getTime(),
};

const mutations = {
  SET_DATE_NOW(state, dateNow) {
    state.dateNow = dateNow;
  },
};

const actions = {
  async getDateNow({ commit }) {
    try {
      const response = await baseCore.get(ENDPOINT);
      commit('SET_DATE_NOW', response.data.data);
      return response.data.data;
    } catch (error) {
      const currentDate = new Date().getTime();
      commit('SET_DATE_NOW', currentDate);
      return currentDate;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
