import { captureError } from '@/Utils/Sentry/captureErrorSentry';
import lmsClient from '@/http/lms';

const ENDPOINT = '/content-follow';

/**
 * Module Structure
 */
const state = {
  userFollow: [],
};

const getters = {
  userFollow: (state) => state.userFollow, // the LESSONS he watched ON THIS device
  userFollowOrdered: (state) =>
    state.userFollow.sort((prev, cur) => new Date(prev.contentFollowUpdatedAt) - new Date(cur.contentFollowUpdatedAt)),
};

const mutations = {
  SET_USER_FOLLOW(state, value) {
    state.userFollow = [...state.userFollow.filter((follow) => follow.lessonID !== value.lessonID), value];
  },
  RESET_FOLLOW_DATA(state) {
    state.userFollow = [];
  },
  SET_USER_FOLLOWS(state, follows) {
    state.userFollow = follows;
  },
};

const actions = {
  setUserFollow({ commit, rootGetters }, follow) {
    const hasCreatedAt = follow.hasOwnProperty('contentFollowCreatedAt');
    const hasUpdatedAt = follow.hasOwnProperty('contentFollowUpdatedAt');

    const newFollow = {
      ...follow,
      userID: rootGetters.dataUser?.userID,
      contentFollowCreatedAt: hasCreatedAt ? follow.contentFollowCreatedAt : new Date().toISOString(),
      contentFollowUpdatedAt: hasUpdatedAt ? follow.contentFollowUpdatedAt : new Date().toISOString(),
    };

    commit('SET_USER_FOLLOW', newFollow);
  },
  setMultipleUserFollow({ rootGetters, commit }, userFollow) {
    if (!Array.isArray(userFollow) || !userFollow.length) return;

    const newFollows = userFollow.map((follow) => {
      return {
        userID: rootGetters.dataUser?.userID,
        courseID: follow.courseID,
        lessonID: follow.lessonID,
        contentFollowMilliSeconds: follow.contentFollowMilliSeconds,
        contentFollowPercentual: follow.contentFollowPercentual,
        contentFollowCompleted: follow.contentFollowCompleted,
        contentFollowCreatedAt: follow?.contentFollowCreatedAt || new Date().toISOString(),
        contentFollowUpdatedAt: follow?.contentFollowUpdatedAt || new Date().toISOString(),
      };
    });

    commit('SET_USER_FOLLOWS', newFollows);
  },
  async createContentFollow({ rootGetters }, progress) {
    try {
      const response = await lmsClient.post(ENDPOINT, {
        ...progress,
        userID: rootGetters.dataUser?.userID,
      });

      return response?.data; // Add null check before accessing 'data' property
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },
  resetFollowData({ commit }) {
    commit('RESET_FOLLOW_DATA');
  },
  async getFollowByCourse({ commit }, { courseID }) {
    try {
      const response = await lmsClient.get(`${ENDPOINT}/${courseID}`);

      response.data.data?.forEach((follow) => {
        commit('SET_USER_FOLLOW', {
          contentFollowCompleted: follow.contentFollowCompleted,
          contentFollowCreatedAt: follow.contentFollowCompletedAt,
          contentFollowMilliSeconds: follow.contentFollowMilliSeconds,
          contentFollowPercentual: follow.contentFollowPercentual,
          contentFollowUpdatedAt: follow.contentFollowUpdatedAt,
          courseID: follow.courseID,
          lessonID: follow.lessonID,
          userID: follow.userID,
        });
      });
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
