import axios from 'axios';
import store from '@/store';

const ENDPOINT = '/partner';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-gprim-specnav': window.navigator.userAgent,
    'X-gprim-specdev': 'WEB',
    'X-gprim-specver': '2.2.1',
  },
});

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (err) => {
    if (err.response && (err.response.status === 401 || err.response.status === 403)) {
      await store.dispatch('currentLogout');
    }
    return Promise.reject(err.response);
  }
);

/**
 * Module structure
 */
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async kinvoCodeVerify({}, benefitCode) {
    try {
      const { data } = await http.post(`${ENDPOINT}/kinvoCodeVerify`, { benefitCode });

      if (data.status) {
        return [null, true];
      }

      return [data, null];
    } catch (error) {
      if (error.data.error === 'CODE_ALREADY_USED') {
        return [null, true];
      }

      return [String(error)];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
