export default function useTexts() {
  function getHighlighted(str) {
    if (typeof str !== 'string' || !str) return null;

    const startIndex = str.indexOf('[');
    const endIndex = str.indexOf(']');

    const start = str.substring(0, startIndex);
    const middle = str.substring(startIndex + 1, endIndex); // to remove brackets
    const end = str.substring(endIndex + 1);

    return { start, middle, end };
  }

  function replaceBlankAndConvertToLowercase(str, newCharacter = '') {
    if (typeof str !== 'string') return str;

    return str.replace(' ', newCharacter).toLowerCase();
  }

  function limitedText(text, maxLength) {
    if (!text || !maxLength) return '';

    const complement = text.length > maxLength ? '...' : '';
    return text.substring(0, maxLength - 1) + complement;
  }

  function removeTextHypen(text) {
    if (!text) return '';
    return text.replaceAll('-', ' ');
  }

  function limitTextToWords(text, wordCount = 16) {
    if (!text || !wordCount) return null;
    const splitText = text.split(' ');
    const textToReturn = splitText.slice(0, wordCount).join(' ');

    if (textToReturn.length === text.length) {
      return textToReturn;
    }

    return textToReturn + '...';
  }

  function pluralize(amount, singular, plural = null) {
    if (plural === null) {
      plural = `${singular}s`;
    }

    return amount > 1 || amount === 0 ? plural : singular;
  }

  function padToDigits(num, amount = 2) {
    return num.toString().padStart(amount, '0');
  }

  return {
    pluralize,
    limitedText,
    padToDigits,
    getHighlighted,
    removeTextHypen,
    limitTextToWords,
    replaceBlankAndConvertToLowercase,
  };
}
