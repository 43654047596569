import lmsClient from '@/http/lms';
import { utils } from '@/mixins/utils';
import useContent from '@/composables/useContent';
import { captureError } from '@/Utils/Sentry/captureErrorSentry';

const { getCourseFiles, flattenCourseLessons } = useContent();

const ENDPOINT = '/courses';
const COURSE_MODULE_EXCEPTION = 'bonus';

const STATUS = {
  PENDING: 'pending',
  SCHEDULED: 'scheduled',
  PUBLISHED: 'published',
};

/**
 * Module Structure
 */
const state = {
  coursesRecommendation: {},
  sections: {
    FINCLASSES: [],
    FINDOCS: [], // TODO: update along with backend
    FINBOOKS: [],
    FINSESSIONS: [],
    FINJOURNEYS: [],
    FINMENTORSHIPS: [],
  },
  lastPublishedFinsession: { id: '', liveNow: false },
  stripes: {
    scheduled: [],
    lastPublished: '',
  },
  recommendations: [],
  courseFollow: [],
  courses: {},
  isLoadingCourseProgress: false,
};

const getters = {
  stripes: (state) => state.stripes,
  sections: (state) => state.sections,
  courseFollow: (state) => state.courseFollow,
  courseByID: (state) => (courseID) => state.courses[courseID],
  recommendations: (state) =>
    state.recommendations.filter((recommendation) => recommendation.courseStatus != 'pending'),
  courseRecommendationByID: (state) => (recommendationID) => state.coursesRecommendation[recommendationID],
  isLoadingCourseProgress: (state) => state.isLoadingCourseProgress,
  lastPublishedFinsession: (state) => state.lastPublishedFinsession,
};

const mutations = {
  SET_SECTIONS(state, sections) {
    state.sections = sections;
  },
  SET_LAST_PUBLISHED_FINSESSION(state, finsession) {
    state.lastPublishedFinsession = finsession;
  },
  SET_COURSE_STRIPES(state, stripes) {
    state.stripes = stripes;
  },
  SET_COURSE_FOLLOW(state, courseFollow) {
    state.courseFollow = courseFollow;
  },
  SET_COURSE(state, { courseID, course }) {
    state.courses[courseID] = course;
  },
  SET_RECOMMENDATIONS(state, recommendations) {
    state.recommendations = recommendations;
  },
  SET_IS_LOADING_COURSE_PROGRESS(state, isLoadingCourseProgress) {
    state.isLoadingCourseProgress = isLoadingCourseProgress;
  },
};

const actions = {
  async fetchCourses({ commit, dispatch }, { page = 1, perPage = 100 } = {}) {
    try {
      const newSections = {
        FINDOCS: [],
        FINBOOKS: [],
        FINCLASSES: [],
        FINSESSIONS: [],
        FINJOURNEYS: [],
        FINMENTORSHIPS: [],
      };

      const response = await lmsClient.get(ENDPOINT, { params: { page, perPage } });
      response.data.data.forEach((section) => {
        if (newSections?.[section.courseGroup]) {
          const isComingSoon = [STATUS.PENDING, STATUS.SCHEDULED].includes(section?.courseStatus);
          newSections[section.courseGroup].push({ ...section, isComingSoon });
        }
      });

      commit('SET_SECTIONS', newSections);
      dispatch('setLastPublishedFinsession', newSections.FINSESSIONS);

      if (page === 1 && perPage === 100) {
        dispatch('setCourseStripes', response.data.data);
      }

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async fetchArticles({}, { page = 1, perPage = 10, articleTag = '', search = '' } = {}) {
    let body = {
      page,
      perPage,
    };

    if (articleTag != '') {
      body.articleTag = articleTag;
    }

    if (search != '') {
      body.search = search;
    }

    try {
      const response = await lmsClient.get('/articles', {
        params: body,
      });

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      return error?.data;
    }
  },

  async fetchCourseByID({}, { courseID } = {}) {
    try {
      const response = await lmsClient.get(`${ENDPOINT}/${courseID}`);

      const courseExtras = utils.methods.flattenCourseExtras(response.data?.data?.courseExtras);
      const courseLessons = flattenCourseLessons(response.data?.data?.courseModules || []);
      const courseFiles = getCourseFiles(courseLessons);

      const __courseLessonsNotBonus = [];
      const __courseLessonsIDsNotBonus = [];

      const __courseLessonsBonus = [];

      courseLessons.forEach((courseLesson) => {
        if (courseLesson.moduleType !== COURSE_MODULE_EXCEPTION) {
          __courseLessonsNotBonus.push(courseLesson);
          __courseLessonsIDsNotBonus.push(courseLesson.lessonID);
        } else {
          __courseLessonsBonus.push(courseLesson);
        }
      });

      const course = {
        ...response.data.data,
        courseExtras,
        courseLessons,
        courseFiles,
        __courseLessonsNotBonus,
        __courseLessonsIDsNotBonus,
        __courseLessonsBonus,
      };

      return {
        ...response.data,
        data: course,
      };
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async fetchCourseRecommendationByID({}, { recommendationID } = {}) {
    try {
      const response = await lmsClient.get(`recommended/${recommendationID}`);

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async fetchRecommendations({ commit }, { numResults = 9 } = {}) {
    try {
      const params = numResults ? { params: { numResults } } : {};
      const response = await lmsClient.get('/courses/recommendations', params);

      commit('SET_RECOMMENDATIONS', response.data.data);

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async fetchCourseProgress({ commit }) {
    try {
      commit('SET_IS_LOADING_COURSE_PROGRESS', true);
      const response = await lmsClient.get(`${ENDPOINT}/progress`);

      commit('SET_COURSE_FOLLOW', response.data.data);
      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    } finally {
      commit('SET_IS_LOADING_COURSE_PROGRESS', false);
    }
  },

  setLastPublishedFinsession({ commit }, finsessions) {
    if (!Array.isArray(finsessions) || !finsessions.length) {
      commit('SET_LAST_PUBLISHED_FINSESSION', { id: '', liveNow: false });
      return;
    }

    const orderedFinsessions = finsessions
      .sort((active, soon) => active.isComingSoon - soon.isComingSoon)
      .filter((item) => {
        return !item?.isComingSoon;
      });
    orderedFinsessions.sort((a, b) =>
      a.courseRelease < b.courseRelease ? 1 : b.courseRelease < a.courseRelease ? -1 : 0
    );

    const lastPublishedFinsession = orderedFinsessions[0];
    const lastPublishedFinsessionCourseExtras = utils.methods.flattenCourseExtras(lastPublishedFinsession.courseExtras);
    const liveNow = lastPublishedFinsessionCourseExtras?.isLive === 'true';

    commit('SET_LAST_PUBLISHED_FINSESSION', {
      id: lastPublishedFinsession.courseID,
      liveNow: liveNow || false,
    });
  },

  setCourseStripes({ commit }, courses) {
    if (!Array.isArray(courses) || !courses.length) {
      commit('SET_COURSE_STRIPES', { scheduled: [], lastPublished: '' });
      return;
    }

    const orderedCourses = courses.sort((a, b) =>
      a.courseRelease < b.courseRelease ? 1 : b.courseRelease < a.courseRelease ? -1 : 0
    );

    const scheduledCoursesID = orderedCourses
      .filter((course) => course.courseStatus.toLowerCase() === 'scheduled')
      .map((course) => course.courseID);

    commit('SET_COURSE_STRIPES', {
      scheduled: scheduledCoursesID,
      lastPublished: scheduledCoursesID.length ? '' : orderedCourses[0].courseID,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
