<template>
  <div class="loading">
    <div class="loading__logo">
      <img src="@/assets/img/logo-xs.svg" class="loading__logo-xs" alt="" />
      <img src="@/assets/img/logo-text.svg" class="loading__logo-txt" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderLogin',
  mounted() {
    setTimeout(() => {
      this.$store.commit('TOOGLELOADER', false);
    }, 3000);
  },
};
</script>
<style scoped lang="scss">
.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2025;
  background: url('@/assets/img/bg-login.png') no-repeat center center $dark-800;
  background-size: cover;

  &__logo {
    position: relative;
    display: flex;
    gap: 0.75rem;

    &-xs {
      position: relative;
      animation: leftAnimation 1s ease-in-out 1s 1 normal backwards;
    }

    &-txt {
      animation: show 1s ease-in-out 1s 1 normal backwards;
    }
  }
}
</style>
