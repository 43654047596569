<template>
  <div @click="openRedoModal" class="upp">
    <div class="upp__profile">
      <span class="upp__profile-label">Perfil</span>
      <div class="upp__profile-status">
        <template v-if="profileStatus.inProgress">
          <h6 class="active">Em andamento</h6>
          <img src="@/assets/img/icon-settings-gear.svg" alt="" />
        </template>
        <template v-else-if="profileStatus.done">
          <h6 class="active">{{ onboardingOutput.toLowerCase() }}</h6>
          <img src="@/assets/img/icon-start-check-mark.svg" alt="" />
        </template>
        <template v-else>
          <h6 class="disabled">Indefinido</h6>
          <img src="@/assets/img/icon-close-white.svg" alt="" />
        </template>
      </div>
    </div>

    <BaseThinProgressBar :is-visible="true" :current="currentStep" :total="5" />

    <div @click.stop="handleAction" class="upp__action">
      <p v-if="profileStatus.inProgress" class="upp__action-description">Finalize sua avaliação de perfil.</p>
      <p v-else-if="profileStatus.done" class="upp__action-description">
        <img src="@/assets/img/icon-arrow-download.svg" alt="" />
        Baixar plano de estudos
      </p>
      <p v-else class="upp__action-description">Vamos descobrir qual é o seu perfil?</p>

      <img src="@/assets/img/arrow-right-white.svg" alt="" />
    </div>
  </div>
</template>

<script>
import { computed, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import useTexts from '@/composables/useTexts';
import { utils as utilsMixins } from '@/mixins/utils';
import { STUDY_PLAN_BASE_URL } from '@/Utils/selectsValues';
import BaseThinProgressBar from '@/components/BaseThinProgressBar';
import useDatalayer, { EVENTS_MAPPING } from '@/composables/useDatalayer';

const {
  ONBOARDING: { name: ONBOARDING, actions: EVENT_ACTIONS },
} = EVENTS_MAPPING;

export default {
  name: 'UserProgressPanel',
  emits: ['closeDropdown'],
  components: { BaseThinProgressBar },
  setup(props, { emit }) {
    const store = useStore();
    const texts = useTexts();
    const datalayer = useDatalayer();
    const currentStep = ref(5);
    const profileStatus = ref({ undefined: true, inProgress: false, done: false });
    const onboardingFollow = computed(() => store.getters['quiz/onboardingFollow']);
    const userOnboardingProfile = computed(() => store.getters['outputs/userOnboardingProfile']);
    const onboardingOutput = ref(userOnboardingProfile.value);

    const calculateProfileStatus = () => {
      const isUndefined = !Array.isArray(onboardingFollow.value) || !onboardingFollow.value.length;
      const isInProgress = !isUndefined && !onboardingOutput.value;

      profileStatus.value = {
        undefined: isUndefined,
        inProgress: isInProgress,
        done: !isInProgress && onboardingOutput.value,
      };
    };

    const calculateCurrentStep = () => {
      if (profileStatus.value.done) currentStep.value = 5;
      else if (profileStatus.value.inProgress) currentStep.value = 3;
      else currentStep.value = 0;
    };

    const handleAction = () => {
      if (profileStatus.value.done) {
        datalayer.push(ONBOARDING, {
          customProps: { action: EVENT_ACTIONS.STUDY_PLAN_OPENED, label: userOnboardingProfile.value },
        });

        const filePath = texts.replaceBlankAndConvertToLowercase(onboardingOutput.value, '/');
        window.open(`${STUDY_PLAN_BASE_URL}${utilsMixins.methods.normalizeString(filePath)}.pdf`, '_blank');
      } else {
        store.dispatch('quiz/setOnboardingForceOpen', true);
      }
    };

    const openRedoModal = () => {
      store.dispatch('quiz/setOnboardingForceOpen', true);
      emit('closeDropdown');
    };

    watch(
      () => store.getters['outputs/userOnboardingProfile'],
      (newUserOnboardingProfile) => {
        onboardingOutput.value = newUserOnboardingProfile;
        calculateProfileStatus();
        calculateCurrentStep();
      }
    );

    onMounted(() => {
      calculateProfileStatus();
      calculateCurrentStep();
    });

    return { currentStep, handleAction, openRedoModal, profileStatus, onboardingOutput };
  },
};
</script>

<style lang="scss" scoped>
.upp {
  width: 100%;
  height: 100px;
  padding: 15px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  background: $dark-700;
  border-radius: 6px;
  cursor: pointer;

  &__profile {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-label {
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 130%;
      letter-spacing: -0.005em;
      color: $white;
    }

    &-status {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      > h6 {
        font-size: 0.75rem;
        line-height: 140%;
        text-align: right;
        text-transform: capitalize;

        &.active {
          font-weight: 700;
          color: $spirit-stone;
        }

        &.disabled {
          font-weight: 400;
          color: $dark-100;
        }
      }

      > img {
        width: 21px;
        height: 21px;
      }
    }
  }

  &__action {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &-description {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 4px;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 130%;
      letter-spacing: -0.005em;
      color: $spirit-stone;
    }

    > img {
      width: 24px;
      height: 24px;
    }
  }
}

@media (min-width: 1440px) {
  .upp {
    height: 114px;
    gap: 16px;
  }
}
</style>
