export const FINCLASS_TERMS_OF_USE =
  'https://dpo.privacytools.com.br/policy-view/xzQQ34MGq/3/policy/pt_BR?s=1698167560849';

export const FINDAY_TERMS_OF_USE =
  'https://dpo.privacytools.com.br/policy-view/Xd29wpENE/3/regulamento-finday-2024/pt_BR?s=1698178086909';

export const FINCLASS_PRIVACY_POLICY =
  'https://dpo.privacytools.com.br/policy-view/NjqogvjNV/1/policy/pt_BR?s=1712089178983';

export const SPITI_TERMS_OF_USE = 'https://www.invistaspiti.com.br/termos-de-uso/';

export const SPITI_PRIVACY_POLICY = 'https://www.invistaspiti.com.br/politica-de-privacidade/';

export const TYPEFORM_LINK = 'https://7bf5a7812e1e4161a474d19129e0e4b3.js.ubembed.com';

export const TYPEFORM_EMBED = '//embed.typeform.com/next/embed.js';
