import { captureError } from '@/Utils/Sentry/captureErrorSentry';
import lmsClient from '@/http/lms';

const ENDPOINT = '/paths';

/**
 * Module Structure
 */
const state = {};

const getters = {};

const mutations = {};

const actions = {
  async fetchPathByID({}, pathID) {
    try {
      const response = await lmsClient.get(`${ENDPOINT}/${pathID}`);

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },
  async fetchPaths({}) {
    try {
      const response = await lmsClient.get(ENDPOINT);

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
