export const KEEP_STORAGE_KEY = '[DNT]'; // DNT = Do Not Touch

export const REDIRECT_ROUTE_AFTER_LOGIN_SESSIONSTORAGE_KEY = 'REDIRECT_ROUTE_AFTER_LOGIN_SESSIONSTORAGE_KEY';

export const WHATSAPP_DEFAULT_URL = 'https://api.whatsapp.com/send?phone=5511913084339&text=';

export const TOP_BAR_WARNING_IDS = {
  SIGNATURE: 'SIGNATURE',
  CREDIT_CARD: 'CREDIT_CARD',
};

export const PAGE_NAMES_TO_NOT_DISPLAY = [
  'Upsell',
  'ShortCheckout',
  'UpsellDeprecated',
  'ShortCheckoutPayment',
  'ShortCheckoutDeprecated',
  'ShortCheckoutPaymentDeprecated',
];

export const uf = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
];

export const countries = [{ value: 'Brazil', label: 'Brasil', icon: require('@/assets/img/brasil-area.svg') }];

/**
 * Modal promotion properties
 */
export const promoModalInfo = {
  promoId: `${KEEP_STORAGE_KEY}vr-cripto-t2`,
  showModal: false,
  mainImage: {
    landscape: 'https://content.finclass.com/finclasses/promo_modal/area_logada/vr-cripto-t2-landscape.png',
    mobile: 'https://content.finclass.com/finclasses/promo_modal/area_logada/vr-cripto-t2-mobile.png',
    tablet: 'https://content.finclass.com/finclasses/promo_modal/area_logada/vr-cripto-t2-tablet.png',
    desktop: 'https://content.finclass.com/finclasses/promo_modal/area_logada/vr-cripto-t2-desktop.png',
  },
  title: 'DESCONTO PARA ALUNOS',
  subtitle: {
    show: false,
    text: '',
  },
  description:
    'Salve seu dinheiro da próxima crise financeira global - enquanto acelera seus ganhos hoje - no único mercado "a prova de governos" do mundo: as criptomoedas.',
  priceImage: {
    landscape: 'https://content.finclass.com/finclasses/promo_modal/area_logada/valores/vr-cripto-t2-landscape.svg',
    mobile: 'https://content.finclass.com/finclasses/promo_modal/area_logada/valores/vr-cripto-t2-mobile.svg',
    tablet: 'https://content.finclass.com/finclasses/promo_modal/area_logada/valores/vr-cripto-t2-tablet.svg',
    alt: 'Preço - Viver de Renda Cripto',
  },
  ctaLabel: 'QUERO GARANTIR MINHA VAGA!',
  ctaBgColor: '#F2A900',
  ctaColor: '#402D01',
  ctaLink: '/order?pmp=LAN-VIN-SBAN-VRC_T2-BFIN-20220322-ORG-BP_VRC_T2_MAR2022-HOT-BANNER_Finclass',
  ctaTargetBlank: false,
  founder: {
    priceImage: {
      landscape:
        'https://content.finclass.com/finclasses/promo_modal/area_logada/valores/vr-cripto-t2-landscape-founder.svg',
      mobile: 'https://content.finclass.com/finclasses/promo_modal/area_logada/valores/vr-cripto-t2-mobile-founder.svg',
      tablet: 'https://content.finclass.com/finclasses/promo_modal/area_logada/valores/vr-cripto-t2-tablet-founder.svg',
      alt: 'Preço para Founders - Viver de Renda Cripto',
    },
    ctaLabel: 'QUERO GARANTIR MINHA VAGA!',
    ctaLink: '/order?pmp=LAN-VIN-SBAN-VRC_T2-BFIN-20220322-ORG-BP_VRC_T2_MAR2022-HOT-BANNER_Finclass',
    discount: '500,00',
  },
  landscapeLogo: {
    src: 'https://content.finclass.com/finclasses/promo_modal/area_logada/vr-cripto-t2-logo.svg',
    alt: 'Viver de Renda Cripto - Logo',
  },
};

export const dynamicPromoModalInfo = {
  promoId: `${KEEP_STORAGE_KEY}zoom-september-01`,
  isActive: false,
  isLifetimeModal: false,
  isLongImage: false,
  modalBgColor: '#FFFFFF',
  noShowMoreColor: '#424242',
  founder: {
    mainImage: {
      landscape: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-landscape.png',
      mobile: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-mobile.png',
      tablet: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-tablet.png',
      desktop: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-desktop.png',
    },
    title: {
      text: 'Como montar sua carteira de Investimentos em época de eleições',
      baseColor: '#424242',
      highlightColor: '',
      fontFamily: 'Proxima Nova Bold',
    },
    subtitle: {
      text: 'Zoom gratuito para assinantes Finclass',
      color: '#424242',
      highlightColor: '',
      fontFamily: 'Proxima Nova Bold',
    },
    description: {
      text: 'Dia 06 de Setembro, às 20:00',
      color: '#424242',
    },
    cta: {
      label: 'PARTICIPAR DO ZOOM GRATUITO',
      link: 'https://finc.ly/bannerfinclasszoom',
      hasTargetBlank: true,
      backgroundColor: '#66D3FF',
      textColor: '#000000',
    },
  },
  lifetime: {
    mainImage: {
      landscape: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-landscape.png',
      mobile: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-mobile.png',
      tablet: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-tablet.png',
      desktop: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-desktop.png',
    },
    title: {
      text: 'Como montar sua carteira de Investimentos em época de eleições',
      baseColor: '#424242',
      highlightColor: '',
      fontFamily: 'Proxima Nova Bold',
    },
    subtitle: {
      text: 'Zoom gratuito para assinantes Finclass',
      color: '#424242',
      highlightColor: '',
      fontFamily: 'Proxima Nova Bold',
    },
    description: {
      text: 'Dia 06 de Setembro, às 20:00',
      color: '#424242',
    },
    cta: {
      label: 'PARTICIPAR DO ZOOM GRATUITO',
      link: 'https://finc.ly/bannerfinclasszoom',
      hasTargetBlank: true,
      backgroundColor: '#66D3FF',
      textColor: '#000000',
    },
  },
  member: {
    mainImage: {
      landscape: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-landscape.png',
      mobile: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-mobile.png',
      tablet: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-tablet.png',
      desktop: 'https://content.finclass.com/finclasses/promo_modal/area_logada/zoom-september-01-desktop.png',
    },
    title: {
      text: 'Como montar sua carteira de Investimentos em época de eleições',
      baseColor: '#424242',
      highlightColor: '',
      fontFamily: 'Proxima Nova Bold',
    },
    subtitle: {
      text: 'Zoom gratuito para assinantes Finclass',
      color: '#424242',
      highlightColor: '',
      fontFamily: 'Proxima Nova Bold',
    },
    description: {
      text: 'Dia 06 de Setembro, às 20:00',
      color: '#424242',
    },
    cta: {
      label: 'PARTICIPAR DO ZOOM GRATUITO',
      link: 'https://finc.ly/bannerfinclasszoom',
      hasTargetBlank: true,
      backgroundColor: '#66D3FF',
      textColor: '#000000',
    },
  },
};

/**
 * Renewal / My Signature actions
 */
export const automaticRenewalBenefits = [
  {
    title: 'Valor atual',
    description:
      'Enquanto a sua assinatura estiver ativa, você fica protegido dos reajustes anuais que os novos alunos terão.',
  },
  {
    title: 'Descontos exclusivos',
    description:
      'Os alunos têm descontos exclusivos em diversos produtos do Grupo Primo. Ex: Imersões presenciais e formações.',
  },
  {
    title: 'Comunidade',
    description: 'Garanta o acesso a comunidade mais engajada do Brasil. Você pode tirar dúvidas e fazer network.',
  },
];

export const thirdPartiesExplanationData = {
  ios: {
    REVERSAL: {
      iconName: 'mysignature/icon-reversal.svg',
      title: 'Como eu faço para solicitar reembolso na App Store?',
      subtitle: 'Para solicitar reembolso, você deve seguir os seguintes passos:',
      steps: [
        'Acesse reportaproblem.apple.com.',
        'Inicie sessão com seu ID Apple e senha.',
        'Toque ou clique em "Eu gostaria de…".',
        'Selecione "Solicitar um reembolso".',
        'Escolha o motivo pelo qual você deseja um reembolso e escolha Seguinte.',
        'Selecione o item ou os itens que você comprou e escolha Enviar.',
      ],
    },
    ACTIVATE_RENEWAL: {
      iconName: 'icon-repeat-filled.svg',
      title: 'Como eu faço para ativar a recorrência na App Store?',
      subtitle: 'Para ativar a recorrência da sua assinatura, você deve seguir os seguintes passos:',
      steps: [
        'Vá para as configurações do seu aparelho',
        'Selecione seu nome',
        'Selecione “Assinaturas”',
        'Selecione “Finclass”',
        'Selecione uma opção para assinar novamente.',
      ],
    },
    DISABLE_RENEWAL: {
      iconName: 'icon-repeat-filled-cancel.svg',
      title: 'Como eu faço para desativar a recorrência na App Store?',
      subtitle: 'Para desativar a recorrência da sua assinatura, você deve seguir os seguintes passos:',
      steps: [
        'Vá para as configurações do seu aparelho',
        'Selecione seu nome',
        'Selecione “Assinaturas”',
        'Selecione “Finclass”',
        'Clique em cancelar e sua assinatura não será renovada no final do período de vigência do seu plano',
      ],
    },
  },
  claro: {
    REVERSAL: null,
    ACTIVATE_RENEWAL: {
      iconName: 'icon-repeat-filled.svg',
      title: 'O gerenciamento de sua assinatura é realizado no ambiente da Claro.',
      subtitle:
        'Para reativar sua assinatura, você deve acessar o aplicativo Claro Móvel ou entrar em contato com o atendimento da Claro.',
      steps: [],
    },
    DISABLE_RENEWAL: {
      iconName: 'icon-repeat-filled-cancel.svg',
      title: 'Como eu faço para desativar a recorrência na Claro?',
      subtitle: 'Para desativar a recorrência da sua assinatura, você deve seguir os seguintes passos:',
      steps: ['Entrando em contato com a operadora.', 'Através do seu aplicativo Claro.'],
    },
  },
};

/**
 * Header options
 */
export const navigateLessonsOptions = [
  {
    icon: require('@/assets/img/types/icon-category-lessons.svg'),
    title: 'Aulas',
    description: 'Cursos ministrados por diferentes experts do mercado financeiro',
    name: 'Finclasses',
    params: undefined,
    active: true,
    releaseSoon: false,
  },
  {
    icon: require('@/assets/img/types/icon-category-practice.svg'),
    title: 'Na Prática',
    description:
      'Aprenda os mais diversos conceitos, estratégias e formas de investir que você viu nas aulas da Finclass!',
    name: 'ExpertDetails',
    params: { id: '1b26359b-1b2b-47a0-ad4f-18c0ca680cc1' },
    active: true,
    releaseSoon: false,
  },
  {
    icon: require('@/assets/img/types/icon-category-finbooks.svg'),
    title: 'Finbooks',
    description: 'Insights práticos sobre os melhores livros do mercado financeiro',
    name: 'Finbooks',
    params: undefined,
    active: true,
    releaseSoon: false,
  },
  {
    icon: require('@/assets/img/types/icon-category-series.svg'),
    title: 'Séries',
    description: 'Documentários que irão abordar temas relacionados aos capítulos de estudos',
    name: 'Finseries',
    params: undefined,
    active: true,
    releaseSoon: false,
  },
  // {
  //   icon: require('@/assets/img/types/icon-category-journeys.svg'),
  //   title: 'Jornadas',
  //   description: 'Aprendizado passo a passo de acordo com o seu objetivo',
  //   name: 'Finjourneys',
  //   params: undefined,
  //   active: true,
  //   newFeature: true,
  //   releaseSoon: false,
  // },
];

/**
 * Onboarding / Quiz
 */
export const STUDY_PLAN_BASE_URL = 'https://assets.finclass.com/studyPlans/';

/**
 * Chat sorting options.
 */
export const chatSortMessageOptions = [
  {
    value: 'live',
    name: 'Live Chat',
  },
  {
    value: 'reacted',
    name: 'Mais reagidas',
  },
];

export const chatSortQuestionOptions = [
  {
    value: 'recent',
    name: 'Mais recentes',
  },
  {
    value: 'upvoted',
    name: 'Mais votadas',
  },
  {
    value: 'answered',
    name: 'Respondidas',
  },
];

export const GRPRIM_ADDRESS = {
  zipcode: '06472001',
  street: 'Avenida Copacabana',
  neighborhood: 'Dezoito do Forte Empresarial/Alphaville.',
  city: 'Barueri',
  state: 'SP',
  number: '325',
};

export const playerDefaultConfig = {
  autoplay: 'any',
  languages: {
    pt: {
      Play: 'Reproduzir',
      Pause: 'Pausar',
      Rewind: 'Voltar 10 segundos',
      Forward: 'Avançar 10 segundos',
      Mute: 'Mudo',
      Unmute: 'Desmutar',
      Subtitles: 'Legendas',
      Settings: 'Configurações',
      Fullscreen: 'Entrar em modo tela-cheia',
      'Non-Fullscreen': 'Sair do modo tela-cheia',
      Quality: 'Resolução',
      Speed: 'Velocidade',
      Text: 'Texto',
      Background: 'Fundo',
      'subtitles settings': 'Configurações',
      'subtitles off': 'Desligado',
      White: 'Branco',
      Black: 'Preto',
      Red: 'Vermelho',
      Green: 'Verde',
      Blue: 'Azul',
      Cyan: 'Ciano',
      Yellow: 'Amarelo',
      Window: 'Janela',
      'Font Size': 'Tamanho da fonte',
      'Text Edge Style': 'Estilo da borda',
      'Font Family': 'Fonte',
      Opaque: 'Opaco',
      'Semi-Transparent': 'Translúcido',
      Transparent: 'Transparente',
      Raised: 'Levantado',
      Depressed: 'Caido',
      Uniform: 'Uniforme',
      Dropshadow: 'Sombreado',
      Reset: 'Resetar',
      Done: 'Concluir',
      'Exit Picture-in-Picture': 'Fechar Picture-in-Picture',
      'Stream Type': 'Tipo de Transmissão',
    },
  },
  html5: {
    nativeTextTracks: false,
  },
};

export const offerCourses = {
  // example
  // '8a993f37-65c9-4236-9d6d-717ad9756819': {
  //   img: require('@/assets/img/banner-cross-spitione.png'),
  //   pageName: '',
  //   pageParams: {},
  //   queryParams: {},
  //   isExternalLink: true,
  //   externalLink:
  //     'https://lp.invistaspiti.com.br/one03-spiti-one/fl/?spt=SF-INL-ALG-ONE03-0-20230512-0-PREMATRICULA-0-CARTEIRA',
  // },
};
