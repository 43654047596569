export const MAX_CREDIT_CARD_EXPIRY_YEAR = '2035';

export const RECAPTCHA_HEADER_KEY = 'x-gprim-key';

export const DEFAULT_COUPON_ERROR_LENGTH = 'Por favor, informe um cupom válido';

export const PAYMENT_GATEWAYS_RENEWAL_ALLOW = ['pagarme', 'vindi'];

export const PAYMENT_GATEWAYS_WITH_REVERSAL_INFORMATION = ['pagarme', 'ios', 'vindi'];

export const PAYMENT_GATEWAYS_WITH_RENEWAL_INFORMATION = ['pagarme', 'ios', 'claro', 'vindi'];

export const PAYMENT_METHODS = {
  CREDIT_CARD: 'credit_card',
  BANK_SLIP: 'bank_slip',
  PIX: 'pix',
};

export const PAYMENT_METHODS_VALIDITY = {
  BANK_SLIP: 'O prazo de validade do boleto é de até 3 dias.',
  PIX: 'O código Pix gerado é válido por 1 dia.',
};
