import store from '@/store';
import router from '@/router';
import { KEEP_STORAGE_KEY } from '@/Utils/selectsValues.js';
import useLocalStorage from '@/composables/useLocalStorage';
import { HAS_ACCESSED_BEFORE_KEY } from '@/Utils/constants/localStorageKeys';

const { removeItem } = useLocalStorage(HAS_ACCESSED_BEFORE_KEY);

export const logoutUserMix = {
  methods: {
    cleanLocalStorageExceptSomeValues() {
      const pairsToKeep = Object.keys(window.localStorage)
        .filter((key) => key.includes(KEEP_STORAGE_KEY))
        .map((key) => {
          const value = window.localStorage.getItem(key);
          return { key, value };
        });

      window.localStorage.clear();

      for (let i = 0; i < pairsToKeep.length; i++) {
        const { key, value } = pairsToKeep[i];
        window.localStorage.setItem(key, value);
      }
    },
    logoutUser(hasReason = false) {
      store.dispatch('doLogout');
      store.dispatch('resetUserGroups');
      store.dispatch('resetRenewalData');
      store.dispatch('auth/setToken');
      store.dispatch('follow/resetFollowData');
      store.dispatch('quiz/resetOnboardingData');
      store.dispatch('setUserIsLifetime', false);
      store.dispatch('outputs/resetUserOnboardingProfile');
      store.dispatch('achievements/clearUserAchievements');

      window.sessionStorage.clear();
      removeItem();
      this.cleanLocalStorageExceptSomeValues();
      this.$posthog.reset();

      // Identity Operator necessary because calls by reference send the PointerEvent implicitly
      if (hasReason === true) {
        store.dispatch(
          'errors/setLoginRedirectReason',
          'Seu token expirou e você foi redirecionado para o login. Por favor, conecte-se novamente!'
        );
      }

      router.push({ name: 'Login' });
    },
    handleGeneralException() {
      store.dispatch('errors/setHomeRedirectReason', {
        message: 'Tivemos um problema no tratamento das informações. Por favor, tente novamente!',
      });

      if (router.currentRoute.name !== 'Home') {
        router.replace({ name: 'Home' });
      }
    },
  },
};
