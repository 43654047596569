import users from '@/http/users';
import oldUsers from '@/http/old-users';
import staticClient from '@/http/static';

const ENDPOINT = '/user';
const RECAPTCHA_HEADER_KEY = 'X-gprim-key';

export const DOWNGRADE_DESCRIPTION = 'Sua assinatura SEMESTRAL foi efetuada com sucesso.';

/**
 * Module structure
 */
const state = {
  filledFields: {
    name: '',
    email: '',
    phone: '',
    creditCard: {
      creditCardCVV: '',
      creditCardName: '',
      creditCardNumber: '',
      creditCardExpirationYear: '',
      creditCardExpirationMonth: '',
    },
  },
  reasonsCancel: null,
  downgradeAlert: null,
  hasCompletedRegister: false,
  hasTopBarWarningCreditCardAlert: false,
};

const getters = {
  filledFields: (state) => state.filledFields,
  reasonsCancel: (state) => state.reasonsCancel,
  downgradeAlert: (state) => state.downgradeAlert,
  hasCompletedRegister: (state) => state.hasCompletedRegister,
  hasTopBarWarningCreditCardAlert: (state) => state.hasTopBarWarningCreditCardAlert,
};

const mutations = {
  SET_HAS_COMPLETED_REGISTER(state, hasCompletedRegister) {
    state.hasCompletedRegister = hasCompletedRegister;
  },
  SET_REGISTER_FILLED_FIELDS(state, { name, email, phone }) {
    state.filledFields = {
      ...state.filledFields,
      name,
      email,
      phone,
    };
  },
  SET_PURCHASE_FILLED_FIELDS(state, { creditCard }) {
    state.filledFields = { ...state.filledFields, creditCard };
  },
  SET_REASONS_CANCEL(state, reasonsCancel) {
    state.reasonsCancel = reasonsCancel;
  },
  SET_DOWNGRADE_ALERT(state, message) {
    state.downgradeAlert = message;
  },
  SET_HAS_TOP_BAR_WARNING_CREDIT_CARD_ALERT(state, hasAlert) {
    state.hasTopBarWarningCreditCardAlert = hasAlert;
  },
};

const actions = {
  async userRegister({}, registerData) {
    if (!registerData) return {};

    try {
      const { data } = await users.post(`${ENDPOINT}/register`, registerData);

      return [null, data?.data];
    } catch (error) {
      return [error?.data, null];
    }
  },

  async userSubscription({}, { subscriptionData, recaptchaToken }) {
    return new Promise(async (resolve, reject) => {
      if (!subscriptionData) reject({});

      let headers = {};
      if (recaptchaToken) {
        headers = { [RECAPTCHA_HEADER_KEY]: recaptchaToken };
      }

      try {
        const response = await oldUsers.post(`${ENDPOINT}/register/subscription`, subscriptionData, { headers });
        resolve(response.data);
      } catch (error) {
        reject(error);
      }
    });
  },

  async verifyCoupon({}, couponCode) {
    return new Promise(async (resolve, reject) => {
      if (!couponCode) reject({});

      try {
        const { data } = await oldUsers.post(`${ENDPOINT}/register/verifyCupom`, { cupom_code: couponCode });
        resolve({ status: data.status, data: data.status ? data.planCupom : data.error });
      } catch (error) {
        reject({});
      }
    });
  },

  setRegisterFilledFields({ commit }, fields) {
    commit('SET_REGISTER_FILLED_FIELDS', fields);
  },

  setPurchaseFilledFields({ commit }, fields) {
    commit('SET_PURCHASE_FILLED_FIELDS', fields);
  },

  setHasCompletedRegister({ commit }, hasCompletedRegister) {
    commit('SET_HAS_COMPLETED_REGISTER', hasCompletedRegister);
  },

  resetFilledFields({ commit }) {
    commit('SET_REGISTER_FILLED_FIELDS', { name: '', email: '', phone: '' });
    commit('SET_PURCHASE_FILLED_FIELDS', { creditCard: null });
  },

  async createTransaction({ rootGetters }, transactionData) {
    if (!transactionData) return {};

    try {
      const { data } = await oldUsers.post('/payments/createTransaction', {
        gateway: 'pagarme',
        userID: rootGetters.dataUser?.userID,
        ...transactionData,
      });

      if (data.status) {
        return [null, data?.data];
      }

      return [data, null];
    } catch (error) {
      if (error instanceof Error) {
        return [error.message];
      }

      return [String(error)];
    }
  },

  setDowngradeAlert({ commit }, message) {
    commit('SET_DOWNGRADE_ALERT', message);
  },

  setHasTopBarWarningCreditCardAlert({ commit }, hasAlert) {
    commit('SET_HAS_TOP_BAR_WARNING_CREDIT_CARD_ALERT', hasAlert);
  },

  async getReasonsCancel({ commit }) {
    const response = await staticClient.get('/platform-reasons-cancel.json');

    if (response && response.data) {
      commit('SET_REASONS_CANCEL', response.data);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
