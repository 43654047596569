import users from '@/http/users';
import { utils as utilsMixins } from '@/mixins/utils';

const ENDPOINT = '/user';

/**
 * Module structure
 */
const state = {
  token: null,
  tokenRefreshedAt: null,
};

const getters = {
  isLogged: (state) => Boolean(state.token),
  token: (state) => state.token,
  tokenRefreshedAt: (state) => state.tokenRefreshedAt,
};

const mutations = {
  SET_TOKEN(state, token) {
    state.token = token;
    state.tokenRefreshedAt = new Date().getDate();
  },
};

const actions = {
  setToken({ commit }, token) {
    commit('SET_TOKEN', token);
  },
  async refreshToken({ commit }) {
    try {
      const response = await users.post(`${ENDPOINT}/token/refresh`);
      if (response.data?.status) {
        commit('SET_TOKEN', response.data.data.newToken);
      }
    } catch (error) {
      console.log('## GPRIM ## Refresh token error.');
    }
  },
  async login({ commit, dispatch }, formData) {
    try {
      const data = await users.post(`${ENDPOINT}/login`, formData);

      commit('TOOGLELOADER', true, { root: true });
      const { data: loginResponse } = data;

      dispatch('setToken', loginResponse.data.token);

      // Note: needed for Google Analytics (https://www.geeksforgeeks.org/how-to-set-up-a-cookie-that-never-expires-in-javascript/)
      const maximunTimeAllowedInInt32Bit = new Date(2147483647 * 1000);
      utilsMixins.methods.setCookie('subscriber_user', true, maximunTimeAllowedInInt32Bit);

      return [null, loginResponse];
    } catch (error) {
      return [error?.data, null];
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
