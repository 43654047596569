/**
 * =====================================================================================================================
 * Copied and pasted from previous code to keep working (but can be revised).
 * =====================================================================================================================
 */
function reducer(value) {
  return String(value)
    .split('')
    .map(Number)
    .reduce((a, b) => a + b, 0);
}

export function isValidCardNumber(cardNumber) {
  const equalTo = cardNumber.length === 15 ? 1 : 0;
  const cardNumbersArray = cardNumber.split('');

  let cardsum = 0;
  for (let index = 0; index < cardNumbersArray.length - 1; index++) {
    if (index % 2 === equalTo) {
      let value = +cardNumbersArray[index] * 2;
      value = value > 9 ? reducer(value) : value;
      cardsum += value;
    } else {
      cardsum += +cardNumbersArray[index] * 1;
    }
  }

  let verifyNumber = 10 - (cardsum % 10);
  verifyNumber = verifyNumber === 10 ? 0 : verifyNumber;
  return +verifyNumber === +cardNumber.substring(cardNumber.length - 1, cardNumber.length);
}

function numberAreDifferents(cpfCnpj) {
  const regex = new RegExp('^(' + cpfCnpj.split('')[0] + ')+$', 'g');
  return !regex.test(cpfCnpj);
}

function getSecondCPFDigit(cpf) {
  let sum = 0;
  for (let index = 1; index <= 10; index++) {
    sum += +cpf.substring(index - 1, index) * (12 - index);
  }
  return (sum * 10) % 11 >= 10 ? 0 : (sum * 10) % 11;
}

function getFirstCPFDigit(cpf) {
  let sum = 0;
  for (let index = 1; index <= 9; index++) {
    sum += +cpf.substring(index - 1, index) * (11 - index);
  }
  return (sum * 10) % 11 >= 10 ? 0 : (sum * 10) % 11;
}

export function isValidCPF(cpf) {
  if (
    +cpf.substring(9, 10) === +getFirstCPFDigit(cpf) &&
    +cpf.substring(10, 11) === +getSecondCPFDigit(cpf) &&
    numberAreDifferents(cpf)
  ) {
    return true;
  }

  return false;
}

export function isValidCNPJ(cnpj) {
  return /^\d{2}\d{3}\d{3}\d{4}\d{2}$/.test(cnpj);
}
