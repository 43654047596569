import * as Sentry from '@sentry/vue';
import store from '@/store';

// Available levels are "fatal", "error", "warning", "log", "info", and "debug".
export const captureError = (error, type = 'log', setUser = true) => {
  Sentry.withScope(function (scope) {
    scope.setLevel(type);

    if (setUser) {
      const user = store.getters['dataUser'];
      scope.setUser({
        id: user.userID,
        email: user.userEmail,
        userFirstname: user.userFirstname,
      });
    }

    Sentry.captureException(new Error(error));
  });
};
