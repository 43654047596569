import { captureError } from '@/Utils/Sentry/captureErrorSentry';
import lmsClient from '@/http/lms';

const ENDPOINT = 'quizzes';
export const MOCKED_COURSE_ID_TO_FILL_QUIZ_FOLLOW = '12bc1944-d7c5-4606-a322-8b1b2aa72407';

/**
 * Module structure
 */
const state = {
  onboarding: {},
  onboardingFollow: [],
  onboardingAnswerLater: false,
  currentQuestion: null,
  currentSelected: [],
  onboardingForceOpen: false,
  isOnboardingOpen: true,
};

const getters = {
  onboarding: (state) => state.onboarding,
  onboardingFollow: (state) => state.onboardingFollow,
  onboardingForceOpen: (state) => state.onboardingForceOpen,
  currentQuestion: (state) => state.currentQuestion || {},
  currentSelected: (state) => state.currentSelected || [],
  onboardingAnswerLater: (state) => state.onboardingAnswerLater,
  isOnboardingOpen: (state) => state.isOnboardingOpen,
};

const mutations = {
  SET_ONBOARDING_QUIZ(state, onboarding) {
    state.onboarding = onboarding;
  },
  SET_CURRENT_QUESTION(state, question) {
    state.currentQuestion = question;
  },
  SET_SELECTED(state, selected) {
    const newValue = [];

    if (selected) {
      newValue.push(selected);
    }

    state.currentSelected = newValue;
  },
  TOGGLE_SELECTED(state, selected) {
    const hasSelectedIndex = (state.currentSelected || []).findIndex((select) => select === selected);
    if (hasSelectedIndex >= 0) {
      state.currentSelected.splice(hasSelectedIndex, 1);
    } else {
      state.currentSelected = [...state.currentSelected, selected];
    }
  },
  RESET_QUESTION_SELECTED(state) {
    state.currentQuestion = null;
    state.currentSelected = [];
  },
  SEND_QUESTION_TO_QUIZ_FOLLOW(state, payload) {
    const { quizID, questionID, questionAnswer } = payload;
    const followIndex = state.onboardingFollow.findIndex((of) => of.questionID === payload.questionID);

    if (followIndex >= 0) {
      state.onboardingFollow.splice(followIndex, 1, { quizID, questionID, questionAnswer });
    } else {
      state.onboardingFollow = [
        ...state.onboardingFollow,
        {
          quizID: payload.quizID,
          questionID: payload.questionID,
          questionAnswer: payload.questionAnswer,
        },
      ];
    }
  },
  SET_ONBOARDING_ANSWER_LATER(state, value) {
    state.onboardingAnswerLater = value;
  },
  RESET_ONBOARDING_DATA(state) {
    state.onboarding = {};
    state.onboardingFollow = [];
    state.currentQuestion = null;
    state.currentSelected = [];
    state.onboardingForceOpen = false;
  },
  SET_ONBOARDING_FORCE_OPEN(state, value) {
    state.onboardingForceOpen = value;
  },
  SET_IS_ONBOARDING_OPEN(state, value) {
    state.isOnboardingOpen = value;
  },
};

const actions = {
  async getOnboardingQuiz({ commit }) {
    try {
      const response = await lmsClient.get(`${ENDPOINT}/${process.env.VUE_APP_ONBOARDING_QUIZ_ID}`);

      commit('SET_ONBOARDING_QUIZ', response.data.data);
      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async fetchQuestionByID({}, { questionID } = {}) {
    try {
      const response = await lmsClient.get(`questions/${questionID}`);

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async fetchQuizAnswers({}, { courseID, quizID } = {}) {
    try {
      const response = await lmsClient.get(`/quiz-follow?courseID=${courseID}&quizID=${quizID}`);

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async sendQuizAnswers({}, { quizID, questionID, questionAnswer, courseID } = {}) {
    try {
      const response = await lmsClient.post(`/quiz-follow`, {
        quizID,
        questionID,
        questionAnswer,
        courseID,
      });

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  setCurrentQuestion({ commit }, question) {
    commit('SET_CURRENT_QUESTION', question);
  },

  setSelected({ commit }, selected) {
    commit('SET_SELECTED', selected);
  },

  toggleSelected({ commit }, selected) {
    commit('TOGGLE_SELECTED', selected);
  },

  async resetQuestionSelected({ commit, state }, send = false) {
    const localStateCopy = { ...state };
    commit('RESET_QUESTION_SELECTED');

    if (send) {
      try {
        const payload = {
          quizID: localStateCopy.onboarding.quizID,
          questionID: localStateCopy.currentQuestion.questionID,
          questionAnswer: localStateCopy.currentSelected.length ? localStateCopy.currentSelected : [],
          courseID: MOCKED_COURSE_ID_TO_FILL_QUIZ_FOLLOW,
        };

        commit('SEND_QUESTION_TO_QUIZ_FOLLOW', payload);
        const response = await lmsClient.post('/quiz-follow', {
          ...payload,
          questionAnswer: payload.questionAnswer.join('|'),
        });

        return response.data;
      } catch (error) {
        console.error('## GPRIM ## error', error);
        captureError(error, 'error');
        return error?.response?.data;
      }
    }
  },

  async getUserQuizFollow() {
    try {
      const response = await lmsClient.get('/quiz-follow');

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  setOnboardingAnswerLater({ commit }, answerLater) {
    commit('SET_ONBOARDING_ANSWER_LATER', answerLater);
  },

  resetOnboardingData({ commit }) {
    commit('RESET_ONBOARDING_DATA');
  },

  setOnboardingForceOpen({ commit }, open) {
    commit('SET_ONBOARDING_FORCE_OPEN', open);
  },

  addQuizFollowProgress({ commit }, quizFollowData) {
    if (!Array.isArray(quizFollowData) || !quizFollowData.length) return;

    quizFollowData.forEach((quizFollow) => commit('SEND_QUESTION_TO_QUIZ_FOLLOW', quizFollow));
  },

  setIsOnboardingOpen({ commit }, state) {
    commit('SET_IS_ONBOARDING_OPEN', state);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
