import { createApp } from 'vue';
import '@/libs/validators';
import App from './App.vue';
import store from './store';
import money from 'v-money';
import router from './router';
import maska from '@/libs/maska';
import vueI18n from '@/libs/vueI18n';
import * as Sentry from '@/libs/sentry';
import toast from '@/libs/vueToastification.js';
import posthogPlugin from './libs/posthog';

const app = createApp(App);

Sentry.init(app, router);

// register posthog plugin
app.use(posthogPlugin);

app.use(store);
app.use(maska);
// register directive v-money and component <money>
app.use(money, { precision: 4 });
app.use(router);
app.use(vueI18n);
app.use(toast.instance, toast.options);

app.directive('lowercase', {
  updated(el, binding, vnode) {
    el.value = el.value.toLowerCase();
  },
});
app.directive('number', {
  updated(el, binding, vnode) {
    el.value = el.value.replace(/[^\d]/g, '');
  },
});
app.directive('letter', {
  updated(el, binding, vnode) {
    el.value = el.value.replace(/[^a-zA-ZÀ-ú\s]/g, '');
  },
});
app.directive('click-outside', {
  beforeMount: (el, binding) => {
    el.clickOutsideEvent = (event) => {
      if (!(el == event.target || el.contains(event.target))) {
        binding.value();
      }
    };
    document.addEventListener('click', el.clickOutsideEvent);
  },
  unmounted: (el) => {
    document.removeEventListener('click', el.clickOutsideEvent);
  },
});

app.mount('#app');
