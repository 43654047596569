import vueI18n from '@/libs/vueI18n';
import router from '@/router';

const ROUTES_NOT_SHOW_OFFER_NOT_FOUND = ['ShortCheckout', 'ShortCheckoutPayment'];

export const ERRORS_MAP = {
  SUBSCRIPTION_PENDING_PAYMENT:
    'Sua renovação não foi realizada! Por isso, você será redirecionado para cadastrar um novo cartão e continuar sua jornada...',
  SUBSCRIPTION_NOT_AVAILABLE:
    'Este usuário não está habilitado para executar assinaturas neste momento - por favor, verifique as informações inseridas ou tente novamente mais tarde.',
  MAXIMUM_REQUEST_REACH:
    'Você atingiu o máximo de solicitações para este pedido. Por favor, aguarde algum tempo para tentar novamente.',
  SUBSCRIPTION_ALREADY_ACTIVE:
    'Já existe uma conta válida para este email e você será redirecionado à página de Login!',
  SUBSCRIPTION_IS_ON_TRIAL:
    'Existe uma assinatura em período de demonstração para esta conta! Você será direcionado ao login agora mesmo.',
  SIMILAR_PLAN_VIGENCY:
    'Já existe um plano contratado com a mesma vigência! Por favor, entre em contato com o suporte para mais informações.',
  SPITI_ORDER_BUMP: 'Desculpe, mas tivemos um erro ao confirmar sua compra! Entre em contato com o suporte',
  SPITI_UPSELL: 'Desculpe, mas tivemos um erro ao confirmar sua compra! Entre em contato com o suporte',
  PROCEED_TO_SUBSCRIPTION:
    'Esse e-mail já possui conta vinculada, finalize a etapa de pagamento para começar a utilizar agora mesmo',
  SUBSCRIPTION_TRUSTED:
    'Esta assinatura consta como desbloqueada pelo nosso time interno! Favor entrar em contato com o suporte para mais informações',
  SUBSCRIPTION_CREATED:
    'Sua conta foi criada e você está muito perto de ter acesso a todo este conteúdo incrível! Para continuar, você ainda precisa preencher as informações de pagamento.',
  SUBSCRIPTION_CANCELED:
    'Sua assinatura foi cancelada e por este motivo você não tem mais acesso ao conteúdo. Porém, você pode renovar agora mesmo preenchendo as informações de pagamento.',
  NOT_FOUND:
    'Não foi possível encontrar o plano selecionado! Por favor, entre em contato com o suporte para mais informações',
  ACTION_FORBIDDEN: 'Não foi possível realizar uma transação nesse cartão de crédito.',
  FRONTEND_DEFAULT_ERROR:
    'Erro ao processar sua requisição! Por favor, tente novamente em alguns instantes ou entre em contato com o suporte.',
  ERR_NETWORK:
    'Ops! Parece que houve um erro ao processar sua solicitação! Por favor, tente novamente em alguns instantes ou entre em contato com o suporte.',
};

export default function useErrorMessages() {
  const getValue = (error) => {
    if (error && typeof error === 'string' && Object.keys(ERRORS_MAP).includes(error.toUpperCase())) {
      return ERRORS_MAP[error.toUpperCase()];
    }

    return ERRORS_MAP['FRONTEND_DEFAULT_ERROR'];
  };

  const getErrorMessage = (error) => {
    if (!errorHasException(error)) {
      let errorTranslated = '';
      if (error) {
        errorTranslated = vueI18n.global.t(error);
      }

      return errorTranslated || ERRORS_MAP[error] || ERRORS_MAP['FRONTEND_DEFAULT_ERROR'];
    }

    return '';
  };

  function errorHasException(error) {
    if (ROUTES_NOT_SHOW_OFFER_NOT_FOUND.includes(router.currentRoute.value.name) && error === 'OFFER_NOT_FOUND') {
      return true;
    }

    return false;
  }

  return { getValue, getErrorMessage };
}
