import { captureError } from '@/Utils/Sentry/captureErrorSentry';
import lmsClient from '@/http/lms';

const ENDPOINT = '/achievements';
const ACHIEVEMENT_TITLE_FOUNDER = 'Founder';
const ACHIEVEMENT_TITLE_LIFETIME = 'Vitalício';

/**
 * Module Structure
 */
const state = {
  userAchievements: [],
};

const getters = {
  userAchievements: (state) => state.userAchievements,
};

const mutations = {
  SET_USER_ACHIEVEMENTS(state, achievements) {
    state.userAchievements = achievements;
  },
};

const actions = {
  checkUserAchievements({ dispatch }) {
    dispatch('fetchUserAchievements');
  },
  async fetchUserAchievements({ commit }) {
    try {
      const response = await lmsClient.get(ENDPOINT);
      const achievements = response.data.data;
      commit('SET_USER_ACHIEVEMENTS', achievements);

      const isFounder = achievements?.filter((a) => a.achievementTitle === ACHIEVEMENT_TITLE_FOUNDER).length > 0;
      commit('SET_USER_IS_FOUNDER', isFounder, { root: true });

      const isLifetime = achievements?.filter((a) => a.achievementTitle === ACHIEVEMENT_TITLE_LIFETIME).length > 0;
      commit('SET_USER_IS_LIFETIME', isLifetime, { root: true });

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },
  clearUserAchievements({ commit }) {
    commit('SET_USER_ACHIEVEMENTS', []);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
