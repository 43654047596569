import { utils as utilsMixins } from '@/mixins/utils';

const CREDIT_CARD_TYPE = 'credit_card';

export const formatPaymentProfile = (paymentMethods = []) => {
  if (!Array.isArray(paymentMethods) || !paymentMethods.length) return [];

  return paymentMethods.map((paymentMethod) => {
    if (paymentMethod?.paymentProfileGateway === 'vindi') {
      return {
        ...paymentMethod,
        methodType: paymentMethod.paymentProfileType,
        methodDetails: {
          brand: paymentMethod.paymentProfileData.creditCardBrand,
          expiration_date: `${paymentMethod.paymentProfileData.creditCardExpirationMonth}/${paymentMethod.paymentProfileData.creditCardExpirationYear}`,
          holder_name: paymentMethod.paymentProfileData.creditCardHolderName,
          last_digits: paymentMethod.paymentProfileData.creditCardLastFourDigits,
        },
      };
    }

    return paymentMethod;
  });
};

export const getUserLastCreditCard = (userPaymentMethods = []) => {
  if (!Array.isArray(userPaymentMethods) || !userPaymentMethods.length) return null;

  const formatedPaymentMethods = formatPaymentProfile(userPaymentMethods);

  const userCreditCards = formatedPaymentMethods.filter((method) => method?.methodType === CREDIT_CARD_TYPE);
  const lastUsedCreditCard = userCreditCards[userCreditCards.length - 1];

  if (!lastUsedCreditCard) return null;

  const { brand, expiration_date, holder_name, last_digits } = lastUsedCreditCard.methodDetails;

  const creditCardIsExpired = utilsMixins.methods.checkIfDateIsExpired(expiration_date);

  return {
    isExpired: creditCardIsExpired,
    brand,
    expirationDate: expiration_date,
    holderName: holder_name,
    last4Digits: last_digits,
  };
};
