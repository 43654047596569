import { hasOwn } from '@/Utils';
import { mapGetters } from 'vuex';

const KEY_PARAMS_PMP = 'pmp';
const DEFAULT_PMP = 'FIN-VEX-HOME-LNK-BFIN-20220613-ORG-F0034-AS-ORG_LINK';

export const shortMonths = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];

const cardFlags = {
  amex: /^3[47][0-9]{13}$/,
  elo: /^4011(78|79)|^43(1274|8935)|^45(1416|7393|763(1|2))|^50(4175|6699|67[0-6][0-9]|677[0-8]|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9])|^627780|^63(6297|6368|6369)|^65(0(0(3([1-3]|[5-9])|4([0-9])|5[0-1])|4(0[5-9]|[1-3][0-9]|8[5-9]|9[0-9])|5([0-2][0-9]|3[0-8]|4[1-9]|[5-8][0-9]|9[0-8])|7(0[0-9]|1[0-8]|2[0-7])|9(0[1-9]|[1-6][0-9]|7[0-8]))|16(5[2-9]|[6-7][0-9])|50(0[0-9]|1[0-9]|2[1-9]|[3-4][0-9]|5[0-8]))/,
  hipercard: /(^606282\d{10}(\d{3})?)|(3841\d{15})$/,
  mastercard: /^(5[1-5]\d{4}|677189)\d{10}$/,
  visa: /^4\d{12}(\d{3})?$/,
  'dinners-club': /^3(0[0-5]|[68]\d)\d{11}$/,
  aura: /^((?!504175))^((?!5067))(^50[0-9])/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  cabal: /(60420[1-9]|6042[1-9][0-9]|6043[0-9]{2}|604400)/,
  sorocred: /^627892|^636414/,
  banescard: /^636117/,
};

export function clearSpacesAndParenthesis(str) {
  return str && str.replaceAll(')', '').replaceAll(' ', '');
}

export function validateContentForChat(str) {
  const textWithoutSpacesAndParentesis = clearSpacesAndParenthesis(str);
  const urlRegex =
    /(?:((?:https?|ftp):\/\/)|ww)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,}))\.?)(?::\d{2,5})?(?:[/?#]\S*)?/;
  const emailRegex =
    /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
  const hasUrl = str?.toLowerCase().match(urlRegex);
  const hasEmail = textWithoutSpacesAndParentesis?.match(emailRegex);
  return !hasUrl && !hasEmail;
}

export function doesCourseHaveLessons(course) {
  try {
    const [module] = course?.courseModules;

    const isValidCourse = !!course;
    const hasModules = hasOwn(course, 'courseModules') && Array.isArray(course?.courseModules);
    const hasModuleEntities = hasOwn(module, 'moduleEntities') && Array.isArray(module?.moduleEntities);

    if (!isValidCourse || !hasModules || !hasModuleEntities) {
      return false;
    }

    return true;
  } catch (err) {
    console.warn(err);
    return false;
  }
}

export const utils = {
  computed: {
    ...mapGetters('courses', ['courseFollow']),
  },
  methods: {
    setCookie(name, value, expires, path, domain, secure) {
      let cookieText = `${encodeURIComponent(name)}=${encodeURIComponent(value)}`;
      if (expires instanceof Date) {
        cookieText += `; expires=${expires.toGMTString()}`;
      }

      if (path) cookieText += `; path=${path}`;
      if (domain) cookieText += `; domain=${domain}`;
      if (secure) cookieText += `; secure`;

      document.cookie = cookieText;
    },
    validateEmail(email) {
      return !/(\w+)(@{1})(\w+)(\.{1})(\w+)/.test(email);
    },
    numericOnly(input) {
      if (input === null) return null;
      let clearString = input.replace(/[^\d]/g, '');
      return clearString;
    },
    numberCellPhone(number) {
      const phone = this.numericOnly(number);
      return phone.replace(/(\d{2})(\d{5})(\d{4})/g, '($1) $2-$3');
    },
    checkFullName(name) {
      return /(\w{2})+\s(\w{1})+/.test(name);
    },
    formatInstant(data) {
      const dataInstant = new Date(data);
      return `${dataInstant.getDate() < 10 ? '0' + dataInstant.getDate() : dataInstant.getDate()}/${
        dataInstant.getMonth() + 1 < 10 ? '0' + (dataInstant.getMonth() + 1) : dataInstant.getMonth() + 1
      }/${dataInstant.getFullYear()}`;
    },
    formatShortDate(date) {
      const newDate = new Date(date);

      const day = newDate.getDate().toString().padStart(2, '0');

      return `${day}/${newDate.getMonth() + 1}/${newDate.getFullYear()}`;
    },
    checkCardNumberisValidEven(cardNumber) {
      let cardsum = 0;
      for (let index = 0; index < cardNumber.split('').length - 1; index++) {
        if (index % 2 === 0) {
          let value = +cardNumber.split('')[index] * 2;
          value = value > 9 ? this.reducer(value) : value;
          cardsum += value;
        } else {
          cardsum += +cardNumber.split('')[index] * 1;
        }
      }
      let verifyNumber = 10 - (cardsum % 10);
      verifyNumber = verifyNumber === 10 ? 0 : verifyNumber;
      return +verifyNumber === +cardNumber.substr(cardNumber.length - 1, cardNumber.length);
    },
    checkCardNumberisValidOdd(cardNumber) {
      let cardsum = 0;
      for (let index = 0; index < cardNumber.split('').length - 1; index++) {
        if (index % 2 === 1) {
          let value = +cardNumber.split('')[index] * 2;
          value = value > 9 ? this.reducer(value) : value;
          cardsum += value;
        } else {
          cardsum += +cardNumber.split('')[index] * 1;
        }
      }
      let verifyNumber = 10 - (cardsum % 10);
      verifyNumber = verifyNumber === 10 ? 0 : verifyNumber;
      return +verifyNumber === +cardNumber.substr(cardNumber.length - 1, cardNumber.length);
    },
    reducer(value) {
      return value
        .toString()
        .split('')
        .map(Number)
        .reduce(function (a, b) {
          return a + b;
        }, 0);
    },
    numberAreDifferents(cpfCnpj) {
      const regex = new RegExp('^(' + cpfCnpj.split('')[0] + ')+$', 'g');
      return !regex.test(cpfCnpj);
    },
    getSecondCPFDigit(cpf) {
      let somaTotal = 0;
      for (let index = 1; index <= 10; index++) {
        somaTotal += +cpf.substring(index - 1, index) * (12 - index);
      }
      return (somaTotal * 10) % 11 >= 10 ? 0 : (somaTotal * 10) % 11;
    },
    getFirstCPFDigit(cpf) {
      let soma = 0;
      for (let index = 1; index <= 9; index++) {
        soma += +cpf.substring(index - 1, index) * (11 - index);
      }
      return (soma * 10) % 11 >= 10 ? 0 : (soma * 10) % 11;
    },
    checkIfCpfIsValid(cpf) {
      if (
        +cpf.substring(9, 10) === +this.getFirstCPFDigit(cpf) &&
        +cpf.substring(10, 11) === +this.getSecondCPFDigit(cpf) &&
        this.numberAreDifferents(cpf)
      ) {
        return true;
      }
      return false;
    },
    cpfFormater(number) {
      const value = number || '00000000000';
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    },
    cnpjFormater(number) {
      const value = number || '00000000000000';
      return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    },
    cardFormater(number) {
      return number.length === 16
        ? number.replace(/(\d{4})(\d{4})(\d{4})(\d{4})/, '$1 $2 $3 $4')
        : number.replace(/(\d{4})(\d{6})(\d{5})/, '$1 $2 $3');
    },
    inputError(input) {
      const inputSelected = {
        name: 'Nome',
        firstname: 'Nome',
        lastname: 'Sobrenome',
        email: 'E-mail',
        confirm_email: 'Confirmação de e-mail',
        phone: 'Telefone',
        country: 'País',
        birthdate: 'Data de nascimento',
        password: 'Senha',
        confirm_password: 'Confirmação ',
        zipcode: 'CEP ',
        state: 'Estado ',
        street: 'Logradouro',
        number: 'Número da residência',
        city: 'Cidade ',
      };
      return inputSelected[input];
    },
    checkIfImageIsValid(file) {
      if (!file?.name || !file?.size) {
        return false;
      }
      return (/\.jpeg$/.test(file.name) || /\.jpg$/.test(file.name)) && file.size < 4000000;
    },
    overwriteFindocsType(type = '') {
      return type.toLowerCase() === 'findocs' ? 'finseries' : type.toLowerCase();
    },
    getBannerType(banner) {
      if ('bannerType' in banner) {
        return banner.bannerType.split(':')[1] || 'finclass';
      }

      return 'finclass';
    },
    getBannerCourseId(banner) {
      if (banner.bannerType === 'finclass:external') {
        return banner.bannerButtonPrimary.match(/^(https?:\/\/)?(?:www\.)?[^/]+(?:\/[^/]+)*\/?$/)[0];
      }
      return banner.bannerButtonPrimary.split(':')[0] || '';
    },
    // @see https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
    isMobileDevice() {
      return window.matchMedia('(orientation: landscape)').matches
        ? window.matchMedia('(max-width: 845px)').matches && window.matchMedia('(max-height: 390px)').matches
        : window.matchMedia('(max-width: 767px)').matches;
    },
    isTabletDevice() {
      return !this.isMobileDevice() && window.matchMedia('(max-width: 1439px)').matches;
    },
    isDesktopDevice() {
      return !this.isTabletDevice() && window.matchMedia('(min-width: 1440px)').matches;
    },
    isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    },
    normalizeString(value) {
      return value.normalize('NFD').replace(/\p{Diacritic}/gu, '');
    },
    getCardFlag(brand) {
      if (!brand) return null;

      try {
        const icon = require('@/assets/img/card-flags/' + brand + '.svg');
        return icon ?? null;
      } catch (error) {
        return null;
      }
    },
    getCardFlagBy(cardNumber) {
      const _cardNumber = cardNumber.replace(/\D/g, '');
      for (const flag in cardFlags) {
        if (cardFlags[flag].test(_cardNumber)) {
          return flag;
        }
      }

      return '';
    },
    // Note: rawExpirationDate arrives like this -> 1123 (backend-platform-users) or this -> 11/2023 (backend-ecommerce-store)
    checkIfDateIsExpired(rawExpirationDate) {
      if (!rawExpirationDate || typeof rawExpirationDate !== 'string') return;
      if (rawExpirationDate.length !== 4 && rawExpirationDate.length !== 7) return;

      const today = new Date();

      const month = rawExpirationDate.replace('/', '').substring(0, 2);
      const year = rawExpirationDate.replace('/', '').substring(2);
      const expirationDate = year.length === 2 ? new Date(`20${year}`, month - 1) : new Date(year, month - 1);

      return expirationDate < today;
    },
    getRemainingDaysFromTodayUntil(endDate) {
      if (!endDate) return null;

      const ONE_DAY_IN_MILLISECONDS = 24 * 60 * 60 * 1000;
      const start = Date.now(); // now
      const end = new Date(endDate).getTime(); // subscriptionCycle end date

      // The result is a negative number (because these days are "missing")
      return Math.round((start - end) / ONE_DAY_IN_MILLISECONDS);
    },
    getCurrentTime() {
      function padToTwoDigitsWithZero(value) {
        return value.padStart(2, 0);
      }

      const hours = new Date().getHours().toString();
      const minutes = new Date().getMinutes().toString();

      return `${padToTwoDigitsWithZero(hours)}:${padToTwoDigitsWithZero(minutes)}`;
    },
    getPercentage(portion = 0, total = 0) {
      if (!portion || !total || typeof portion !== 'number' || typeof total !== 'number') {
        return `0%`;
      }

      const percentage = ((portion / total) * 100).toFixed(2);
      return `${percentage}%`;
    },
    getCookie(cookie) {
      const cookieData = new RegExp(`(?:^|;\\s*)${cookie}\\s*=\\s*([^;]+)`);
      const match = document.cookie.match(cookieData);
      return match ? match[1] : undefined;
    },
    getPlanBaseCleanedTitle(planTitle) {
      // Note: planTitle arrive like: 'FINCLASS | 4X'
      try {
        return String(planTitle)?.split(' | ')[0];
      } catch (error) {
        return 'FINCLASS';
      }
    },
    courseHaveLessons(course) {
      return doesCourseHaveLessons(course);
    },
    flattenCourseExtras(courseExtras = []) {
      if (!courseExtras || !Array.isArray(courseExtras)) return courseExtras;

      try {
        const flattenedCourseExtras = {};
        courseExtras.forEach((property) => {
          flattenedCourseExtras[property.key] = property.value;
        });

        return flattenedCourseExtras;
      } catch (error) {
        return courseExtras;
      }
    },
    async getCourseIsEnded(courseID) {
      const courses = this.courseFollow;
      const currentCourseIsEnded =
        courses.filter((course) => course.courseID === courseID)[0]?.contentFollowPercentual >= 100;

      return currentCourseIsEnded ? true : false;
    },
    hasPMP(currentHref) {
      const url = new URL(currentHref);
      return url.searchParams.has(KEY_PARAMS_PMP);
    },
    addDefaultPMP(currentHref) {
      const url = new URL(currentHref);
      url.searchParams.append(KEY_PARAMS_PMP, DEFAULT_PMP);
      return url.href;
    },
    getInstallmentsPriceAfterTrialDays(plan) {
      try {
        if (!plan.planTrialDays) return plan.planPrice;

        let installmentsAmount = 12;
        if (plan?.planInstallments) {
          installmentsAmount = plan.planInstallments;
        }

        const stringPlanPrice = plan.planPrice.split('R$')[1]?.trim();
        const planPrice = parseFloat(stringPlanPrice.replace(',', '.'));
        const installmentPrice = (planPrice / installmentsAmount).toFixed(2);
        return `de ${installmentsAmount}x de R$ ${String(installmentPrice).replace('.', ',')}`;
      } catch (error) {
        return plan?.planPrice || '';
      }
    },
    isValidReleaseDate(initialDate) {
      if (typeof initialDate !== 'string') return false;

      try {
        const parsedDate = Date.parse(initialDate);
        const releaseDate = new Date(parsedDate);

        const date = new Date(Date.now());
        const userTimezoneOffset = date.getTimezoneOffset() * 60000;
        const currentDateWithoutGMT = new Date(date.getTime() - userTimezoneOffset);

        return currentDateWithoutGMT >= releaseDate;
      } catch (error) {
        console.log('## GPRIM ## Date checked failed.', error);
        return false;
      }
    },
    getSubscriptionPeriod(intervalCount, intervalType) {
      if (!intervalCount && !intervalType) return null;

      if (intervalType === 'year') return 'Plano Anual';
      else if (intervalType === 'month') return 'Plano Mensal';

      const isBiannualPlan = intervalCount >= 180 && intervalCount <= 190;
      if (isBiannualPlan) return 'Plano Semestral';

      return null;
    },
    keepSavedPosition(queryParams = {}) {
      const queryParamsToKeepSavedPosition = ['cupom', 'coupon', 'upsell', 'bump-offer', 'tab'];

      return queryParamsToKeepSavedPosition.some((query) => Object.keys(queryParams).includes(query));
    },
    msTime(s) {
      // Pad to 2 or 3 digits, default is 2
      function pad(n, z) {
        z = z || 2;
        return ('00' + n).slice(-z);
      }

      const ms = s % 1000;
      s = (s - ms) / 1000;
      const secs = s % 60;
      s = (s - secs) / 60;
      const mins = s % 60;
      const hrs = (s - mins) / 60;

      return pad(hrs) + ':' + pad(mins) + ':' + pad(secs);
    },
  },
};
