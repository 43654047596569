import users from '@/http/users';
import posthog from 'posthog-js';
import { RECAPTCHA_HEADER_KEY } from '@/Utils/constants/ecommerce';

const state = {
  pmp: null,
  cart: null,
  coupon: null,
  addedBumps: [],
  cartInstallments: null,
  isPending: false,
  bumpPriceByInstallment: {},
  priceByInstallment: [],
  isSubmittingCart: false,
  isSubmittingCoupon: false,
  paymentCondition: {
    paymentConditionID: '',
    paymentConditionMethod: '',
  },
  userAddress: {},
};

const getters = {
  getPMP: (state) => state.pmp,
  getCart: (state) => state.cart,
  getIsPending: (state) => state.isPending,
  getCoupon: (state) => state.coupon,
  getIsSubmittingCart: (state) => state.isSubmittingCart,
  getCartInstallments: (state) => state.cartInstallments,
  getPaymentCondition: (state) => state.paymentCondition,
  getIsSubmittingCoupon: (state) => state.isSubmittingCoupon,
  getPriceByInstallment: (state) => state.priceByInstallment,
  getUserAddress: (state) => state.userAddress,
  getAddedBumpsIDs: (state) => state.addedBumps.map((bump) => bump.bumpID),
  getBumpPriceByInstallment: (state) => (bumpID) => state.bumpPriceByInstallment[bumpID] || {},
};

const mutations = {
  SET_PMP(state, { pmp }) {
    state.pmp = pmp;
  },
  SET_CART(state, { cart }) {
    state.cart = cart;
  },
  SET_COUPON(state, { coupon }) {
    state.coupon = coupon;
  },
  SET_BUMPS_PRICE_BY_INSTALLMENT(state, { value }) {
    const bumpPrices = value?.reduce(
      (acc, next) => ({
        ...acc,
        [next.itemID]: {
          bumpItemPriceByInstallment: next.bumpItemPriceByInstallment,
          bumpItemPriceByInstallmentBase: next.bumpItemPriceByInstallmentBase,
        },
      }),
      {}
    );

    state.bumpPriceByInstallment = bumpPrices;
  },
  SET_IS_SUBMITTING_CART(state, { isSubmittingCart }) {
    state.isSubmittingCart = isSubmittingCart;
  },
  SET_IS_SUBMITTING_COUPON(state, { isSubmittingCoupon }) {
    state.isSubmittingCoupon = isSubmittingCoupon;
  },
  SET_IS_PENDING(state, isPending) {
    state.isPending = isPending;
  },
  SET_CART_INSTALLMENTS(state, { cartInstallments }) {
    state.cartInstallments = cartInstallments;
  },
  SET_PAYMENT_CONDITION(state, { paymentCondition }) {
    state.paymentCondition = paymentCondition;
  },
  SET_PRICE_BY_INSTALLMENTS(state, { priceByInstallment }) {
    state.priceByInstallment = priceByInstallment;
  },
  ADD_BUMP(state, bumpToAdd) {
    const bumpAlreadyAdded = state.addedBumps.find((bump) => bump.bumpID === bumpToAdd.bumpID);
    if (!bumpAlreadyAdded) {
      state.addedBumps.push(bumpToAdd);
    }
  },
  REMOVE_BUMP(state, bumpToRemove) {
    const bumpIndex = state.addedBumps.findIndex((bump) => bump.bumpID === bumpToRemove.bumpID);
    if (bumpIndex !== -1) {
      state.addedBumps.splice(bumpIndex, 1);
    }
  },
  CLEAR_CART_BUMPS(state) {
    state.addedBumps = [];
  },
  SET_USER_ADDRESS(state, { userAddress }) {
    state.userAddress = {
      ...userAddress,
      addressZipCode: userAddress?.addressZipCode ? userAddress.addressZipCode.split('-').join('') : '',
    };
  },
};

const actions = {
  async setCartInstallments({ commit, dispatch, rootGetters }, { cartInstallments }) {
    commit('SET_CART_INSTALLMENTS', { cartInstallments });
    dispatch('offerCart', { offerSlug: rootGetters['offer/getCurrentOfferSlug'] });
  },
  async assertCoupon({ rootGetters, commit, dispatch }, { couponCode }) {
    commit('SET_COUPON', { coupon: { couponCode: couponCode } });

    try {
      commit('SET_IS_SUBMITTING_COUPON', { isSubmittingCoupon: true });
      const response = await dispatch('offerCart', { offerSlug: rootGetters['offer/getCurrentOfferSlug'] });

      return response?.coupon;
    } finally {
      commit('SET_IS_SUBMITTING_COUPON', { isSubmittingCoupon: false });
    }
  },
  async offerCart({ getters, rootGetters, commit }, { offerSlug, oldCartID = '', userID = '' }) {
    const pmp = getters['getPMP'];
    const user = userID || rootGetters['dataUser'];
    const couponCode = getters['getCoupon']?.couponCode;
    const installments = getters['getCartInstallments'];
    const paymentConditionID = getters['getPaymentCondition']?.paymentConditionID;
    const paymentConditionMethod = getters['getPaymentCondition']?.paymentConditionMethod;
    const userAddress = getters['getUserAddress'];
    const getAddedBumpsIDs = getters['getAddedBumpsIDs'];

    const shouldSendInstallments = installments && paymentConditionMethod === 'credit_card';

    try {
      commit('SET_IS_SUBMITTING_CART', { isSubmittingCart: true });
      if (user?.userDocument && user?.userDocument?.documentType === 'OTHERS') {
        user.userDocument.documentType = 'OTHER';
      }

      const { data: response } = await users.put(`ecommerce/offers/${offerSlug}/cart`, {
        ...(couponCode && {
          couponCode: couponCode,
        }),
        ...(shouldSendInstallments && {
          cartInstallments: installments,
        }),
        ...(paymentConditionID && {
          paymentConditionID: paymentConditionID,
        }),
        ...(pmp && {
          cartPMP: pmp,
        }),
        ...(user?.userID && {
          userID: user.userID,
        }),
        ...(user?.userDocument && Object.keys(user.userDocument).length ? { userDocument: user.userDocument } : {}),
        ...(oldCartID && {
          oldCartID: oldCartID,
        }),
        ...(userAddress?.addressZipCode && {
          userAddress,
        }),
        ...(getAddedBumpsIDs && {
          cartBumps: getAddedBumpsIDs,
        }),
      });

      commit(
        'offer/SET_OFFER',
        { offerSlug: response.data.offer.offerSlug, offer: { offer: response.data.offer } },
        { root: true }
      );
      commit('offer/SET_CURRENT_OFFER_SLUG', { offerSlug: response.data.offer.offerSlug }, { root: true });
      commit('SET_BUMPS_PRICE_BY_INSTALLMENT', { value: response.data.bumpsPriceByInstallment });

      commit('SET_COUPON', { coupon: response.data.coupon || null });
      commit('SET_CART_INSTALLMENTS', { cartInstallments: response.data.cart.cartInstallments });
      commit('SET_PRICE_BY_INSTALLMENTS', { priceByInstallment: response.data.priceByInstallment });
      commit('SET_CART', { cart: response.data.cart });

      const cartPaymentConditionID = response.data.cart.paymentConditionID;
      const cartPaymentCondition = response.data.offer.offerPaymentConditions.find((offerPaymentCondition) => {
        return offerPaymentCondition.paymentConditionID === cartPaymentConditionID;
      });
      commit('SET_PAYMENT_CONDITION', {
        paymentCondition: {
          paymentConditionID: cartPaymentConditionID,
          paymentConditionMethod: cartPaymentCondition.paymentConditionMethod,
        },
      });

      return response.data;
    } catch (error) {
      return error;
    } finally {
      commit('SET_IS_SUBMITTING_CART', { isSubmittingCart: false });
    }
  },
  bumpChanges({ commit, dispatch, rootGetters }, { operation, bump }) {
    const commitOperation = operation === 'add' ? 'ADD_BUMP' : 'REMOVE_BUMP';

    commit(commitOperation, bump);
    commit('SET_IS_PENDING', true);
    dispatch('offerCart', {
      offerSlug: rootGetters['offer/getCurrentOfferSlug'],
    }).finally(() => {
      commit('SET_IS_PENDING', false);
    });
  },
  clearCartBumps({ commit }) {
    commit('CLEAR_CART_BUMPS');
  },
  async cartPurchase(
    { getters, commit },
    { paymentDetails, userID, recaptchaToken, paymentProfileID, event = 'event_purchase' }
  ) {
    try {
      const coupon = getters['getCoupon'];
      const couponCode = coupon?.couponApplied ? coupon?.couponCode : null;

      const headers = {
        ...(recaptchaToken && {
          [RECAPTCHA_HEADER_KEY]: recaptchaToken,
        }),
      };

      const cartID = getters['getCart']?.cartID;
      const paymentConditionMethod = getters['getPaymentCondition']?.paymentConditionMethod;

      if (cartID && paymentConditionMethod) {
        const { data: response } = await users.post(
          'ecommerce/cartPurchase',
          {
            cartID,
            userID,
            ...(paymentProfileID && {
              paymentProfileID: paymentProfileID,
            }),
            ...(!paymentProfileID && {
              paymentMethod: paymentConditionMethod,
              ...(paymentDetails && {
                paymentDetails: paymentDetails,
              }),
            }),
            ...(couponCode && {
              couponCode: couponCode,
            }),
          },
          headers
        );

        const result = response.data;

        posthog.capture(event, {
          order: result.order,
          products: result.products,
          transaction: result.transaction,
        });

        return result;
      }
    } catch (error) {
      return error;
    }
  },
  cartReset({ commit }, { pmp, couponCode }) {
    commit('SET_PMP', { pmp: pmp });
    commit('SET_COUPON', { couponCode: couponCode });
    commit('SET_CART_INSTALLMENTS', { cartInstallments: null });
    commit('SET_PAYMENT_CONDITION', { paymentCondition: { paymentConditionID: '', paymentConditionMethod: '' } });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
