const shortMonths = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
const longMonthNames = {
  0: 'Janeiro',
  1: 'Fevereiro',
  2: 'Março',
  3: 'Abril',
  4: 'Maio',
  5: 'Junho',
  6: 'Julho',
  7: 'Agosto',
  8: 'Setembro',
  9: 'Outubro',
  10: 'Novembro',
  11: 'Dezembro',
};

export default class Utils {
  static numericOnly(input) {
    const clearString = input.replace(/[^\d]/g, '');
    return clearString;
  }

  static formatDate(data) {
    return data.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3/$2/$1');
  }

  static checkFullName(name) {
    return /(\w{2})+\s(\w{1})+/.test(name);
  }

  static formatInstant(data) {
    const dataInstant = new Date(data);
    return `${dataInstant.getDate() < 10 ? '0' + dataInstant.getDate() : dataInstant.getDate()}/${
      dataInstant.getMonth() + 1 < 10 ? '0' + (dataInstant.getMonth() + 1) : dataInstant.getMonth() + 1
    }/${dataInstant.getFullYear()}`;
  }
}

export function hasOwn(object, property) {
  return object && Object.prototype.hasOwnProperty.call(object, property);
}

export function formatDate(date, short = true) {
  const newDate = new Date(date);

  const day = newDate.getDate().toString().padStart(2, '0');

  return `${day} de ${
    short ? shortMonths[newDate.getMonth()] : longMonthNames[newDate.getMonth()]
  } de ${newDate.getFullYear()}`;
}

export function limitValueBetween(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

export function isMobileDevice() {
  return window.matchMedia('(orientation: landscape)').matches
    ? window.matchMedia('(max-width: 845px)').matches && window.matchMedia('(max-height: 390px)').matches
    : window.matchMedia('(max-width: 767px)').matches;
}

export function normalizeString(value) {
  return value.normalize('NFD').replace(/\p{Diacritic}/gu, '');
}

export function formatExtensiveDate(date) {
  const newDate = new Date(date);

  const day = newDate.getDate().toString().padStart(2, '0');

  return `${day} de ${shortMonths[newDate.getMonth()]}. de ${newDate.getFullYear()}`;
}
