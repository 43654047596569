export default function useLocalStorage(key) {
  const localStorageData = window.localStorage.getItem(key);

  if (!localStorageData) {
    window.localStorage.setItem(key, '');
  }

  function setItem(itemToSave) {
    window.localStorage.setItem(key, JSON.stringify(itemToSave));
  }

  function removeItem() {
    window.localStorage.removeItem(key);
  }

  return { localStorageData, setItem, removeItem };
}
