import router from '@/router';
import users from '@/http/users';
import auth from './modules/auth';
import quiz from './modules/quiz';
import paths from './modules/paths';
import errors from './modules/errors';
import follow from './modules/follow';
import search from './modules/search';
import ratings from './modules/ratings';
import courses from './modules/courses';
import time from './modules/time';
import outputs from './modules/outputs';
import partners from './modules/partners';
import settings from './modules/settings';
import promotions from './modules/promotions';
import ecommerce from './modules/ecommerce/index';
import achievements from './modules/achievements';
import subscription from './modules/subscription';
import mentorship from './modules/mentorship';

import { createStore } from 'vuex';
import * as Sentry from '@sentry/vue';
import { logoutUserMix } from '@/mixins/index';
import { utils as utilsMixins } from '@/mixins/utils';
import createdPersistedState from 'vuex-persistedstate';
import { getUserLastCreditCard, formatPaymentProfile } from '@/Utils/handleCreditCredit';

const baseURL = process.env.VUE_APP_BASE_URL;
import { TOP_BAR_WARNING_IDS } from '@/Utils/selectsValues';

export default createStore({
  modules: {
    auth,
    quiz,
    paths,
    errors,
    follow,
    search,
    ratings,
    courses,
    outputs,
    time,
    partners,
    settings,
    promotions,
    ...ecommerce,
    achievements,
    subscription,
    mentorship,
  },
  state: {
    user: {},
    loaderHome: false,
    loader: false,
    userIsFounder: false,
    userIsLifetime: false,
    ticketGenerated: {
      barcode: null,
      transactionID: null,
      planPrice: null,
      expiresAt: null,
      ticketUrl: null,
      signatureType: null,
    },
    topBarWarning: [],
    showTopBarWarning: false,
    pointWarning: [],
    lastUsedCreditCard: {
      isExpired: false,
      brand: '',
      expirationDate: '',
      holderName: '',
      last4Digits: '',
    },
    currentSignature: {
      filled: false,
      paymentGateway: null,
      paymentMethod: null,
      isRecurrent: null,
      cycleStartDate: null,
      cycleEndDate: null,
      isRenewed: null,
      isExpired: null,
      remainingDays: null,
      externalPlanID: null,
    },
    gtm: {
      renewalFlowType: null,
    },
  },
  mutations: {
    SET_USER_DATA(state, { user }) {
      state.user = user;
    },
    SET_USER_DATA_PROPERTIES(state, { user }) {
      state.user = Object.assign(state.user, user);
    },
    LOGOOUT(state) {
      state.currentSignature = {
        filled: false,
        paymentGateway: null,
        paymentMethod: null,
        isRecurrent: null,
        cycleStartDate: null,
        cycleEndDate: null,
        isRenewed: null,
        isExpired: null,
        remainingDays: null,
        externalPlanID: null,
      };
    },
    TOOGLELOADER(state, value) {
      state.loaderHome = value;
    },
    TOOGLELOADERGENERAL(state, show) {
      state.loader = show;
    },
    SET_USER_IS_FOUNDER(state, value) {
      state.userIsFounder = value;
    },
    SET_USER_IS_LIFETIME(state, value) {
      state.userIsLifetime = value;
    },
    SET_TICKET_GENERATED(state, ticket) {
      state.ticketGenerated = ticket;
    },
    LOGOUT(state) {
      state.user = {};
    },
    ADD_TOP_BAR_WARNING_ID(state, newId) {
      if (state.topBarWarning.includes(newId)) return;

      state.topBarWarning = [...state.topBarWarning, newId];
    },
    REMOVE_TOP_BAR_WARNING_ID(state, idToRemove) {
      const index = state.topBarWarning.findIndex((id) => id === idToRemove);
      if (index >= 0) {
        state.topBarWarning.splice(index, 1);
      }
    },
    SET_SHOW_TOP_BAR_WARNING(state, show) {
      state.showTopBarWarning = show;
    },
    ADD_POINT_WARNING_ID(state, newId) {
      if (state.pointWarning.includes(newId)) return;

      state.pointWarning = [...state.pointWarning, newId];
    },
    REMOVE_POINT_WARNING_ID(state, idToRemove) {
      const index = state.pointWarning.findIndex((id) => id === idToRemove);
      if (index >= 0) {
        state.pointWarning.splice(index, 1);
      }
    },
    SET_LAST_USED_CREDIT_CARD(state, lastUsedCreditCard) {
      state.lastUsedCreditCard = lastUsedCreditCard;
    },
    SET_CURRENT_SIGNATURE(state, signatureData) {
      state.currentSignature = { ...signatureData, filled: true };
    },
    RESET_USER_GROUPS(state) {
      state.userIsFounder = false;
      state.userIsLifetime = false;
    },
    RESET_RENEWAL_DATA(state) {
      state.currentSignature = {
        filled: false,
        paymentGateway: null,
        paymentMethod: null,
        isRecurrent: null,
        cycleStartDate: null,
        cycleEndDate: null,
        isRenewed: null,
        isExpired: null,
        remainingDays: null,
        externalPlanID: null,
      };

      state.lastUsedCreditCard = {
        isExpired: false,
        brand: '',
        expirationDate: '',
        holderName: '',
        last4Digits: '',
      };

      state.topBarWarning = [];
      state.showTopBarWarning = false;
      state.pointWarning = [];
    },
    SET_RENEWAL_FLOW_TYPE(state, flowType) {
      state.gtm.renewalFlowType = flowType === 'TOP_BAR_WARNING' ? 'TOP_BAR_WARNING' : 'POINT_WARNING';
    },
  },
  actions: {
    loginUser({ commit, dispatch }, user) {
      return new Promise((resolve) => {
        commit('TOOGLELOADERGENERAL', true);

        fetch(baseURL + 'user/login', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(user),
        })
          .then(async (response) => {
            const respo = await response.json().then((res) => res);
            if (respo.status) {
              commit('TOOGLELOADER', true);
              dispatch('auth/setToken', respo.data.token);

              // https://www.geeksforgeeks.org/how-to-set-up-a-cookie-that-never-expires-in-javascript/
              const maximunTimeAllowedInInt32Bit = new Date(2147483647 * 1000);
              utilsMixins.methods.setCookie('subscriber_user', true, maximunTimeAllowedInInt32Bit);
            }
            resolve(respo);
          })
          .finally(() => commit('TOOGLELOADERGENERAL', false));
      });
    },
    getUserData({ commit, dispatch }) {
      return new Promise((resolve) => {
        users
          .get('/user/readMe')
          .then((response) => {
            if (response.data.status) {
              const { userFollow, ...user } = response.data.data;

              Sentry.setUser({ email: user?.userEmail, id: user?.userID });

              commit('SET_USER_DATA', { user });

              dispatch('setLastUsedCreditCard', {
                userDocument: user?.userDocument?.documentRegister,
                userLastCreditCard: getUserLastCreditCard(
                  formatPaymentProfile([...(user?.userPaymentMethods || []), ...(user?.paymentProfiles || [])])
                ),
              });

              dispatch('follow/setMultipleUserFollow', userFollow);
            }
            resolve(response.data);
          })
          .catch(() => {
            dispatch('auth/setToken', null);
            commit('LOGOOUT');
            commit('TOOGLELOADER');
          });
      });
    },
    setUserIsFounder({ commit }, value) {
      commit('SET_USER_IS_FOUNDER', value);
    },
    setUserIsLifetime({ commit }, value) {
      commit('SET_USER_IS_LIFETIME', value);
    },
    setTicketGenerated({ commit }, ticket) {
      commit('SET_TICKET_GENERATED', ticket);
    },
    doLogout({ commit }) {
      commit('LOGOUT');
    },
    currentLogout({ dispatch }) {
      dispatch('doLogout');
      dispatch('resetUserGroups');
      dispatch('resetRenewalData');
      dispatch('auth/setToken');
      dispatch('follow/resetFollowData');
      dispatch('quiz/resetOnboardingData');
      dispatch('outputs/resetUserOnboardingProfile');

      window.sessionStorage.clear();
      logoutUserMix.methods.cleanLocalStorageExceptSomeValues();

      dispatch(
        'errors/setLoginRedirectReason',
        'Seu token expirou e você foi redirecionado para o login. Por favor, conecte-se novamente!'
      );

      router.push({ name: 'Login' });
    },
    addTopBarWarningId({ commit }, newId) {
      commit('ADD_TOP_BAR_WARNING_ID', newId);
    },
    removeTopBarWarningId({ commit }, idToRemove) {
      commit('REMOVE_TOP_BAR_WARNING_ID', idToRemove);
    },
    updateShowTopBarWarning({ commit }, show) {
      commit('SET_SHOW_TOP_BAR_WARNING', show);
    },
    addPointWarningId({ commit }, newId) {
      commit('ADD_POINT_WARNING_ID', newId);
    },
    removePointWarningId({ commit }, idToRemove) {
      commit('REMOVE_POINT_WARNING_ID', idToRemove);
    },
    setLastUsedCreditCard({ getters, commit, dispatch }, { userDocument, userLastCreditCard }) {
      if (userLastCreditCard) {
        const { isExpired, brand, expirationDate, holderName, last4Digits } = userLastCreditCard;

        commit('SET_LAST_USED_CREDIT_CARD', {
          isExpired,
          brand,
          expirationDate,
          holderName,
          last4Digits,
          cpf: userDocument || '',
        });

        if (isExpired) {
          dispatch('addPointWarningId', TOP_BAR_WARNING_IDS.CREDIT_CARD);

          if (!getters['userIsLifetime']) {
            dispatch('addTopBarWarningId', TOP_BAR_WARNING_IDS.CREDIT_CARD);
          }
        } else {
          dispatch('removePointWarningId', TOP_BAR_WARNING_IDS.CREDIT_CARD);
          dispatch('removeTopBarWarningId', TOP_BAR_WARNING_IDS.CREDIT_CARD);
          dispatch('subscription/setHasTopBarWarningCreditCardAlert', false);
        }
      }
    },
    setCurrentSignature({ commit }, signatureData) {
      commit('SET_CURRENT_SIGNATURE', signatureData);
    },
    resetUserGroups({ commit }) {
      commit('RESET_USER_GROUPS');
    },
    resetRenewalData({ commit }) {
      commit('RESET_RENEWAL_DATA');
    },
    setRenewalFlowType({ commit }, flowType) {
      commit('SET_RENEWAL_FLOW_TYPE', flowType);
    },
    completeProfile({ dispatch }, userData) {
      return new Promise((resolve, reject) => {
        fetch(baseURL + 'user/completeProfile', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(userData),
        })
          .then((raw) => raw.json())
          .then((response) => {
            if (response.status) {
              dispatch('auth/setToken', response.data.token);
            }
            resolve(response);
          })
          .catch((err) => reject(err));
      });
    },
  },
  plugins: [
    createdPersistedState({
      key: '__finclass',
    }),
  ],
  getters: {
    dataUser: (state) => state.user,
    loaderHome: (state) => Boolean(state.loaderHome),
    loader: (state) => Boolean(state.loader),
    userIsFounder: (state) => state.userIsFounder,
    userIsLifetime: (state) => state.userIsLifetime,
    ticketGenerated: (state) => state.ticketGenerated,
    topBarWarning: (state) => state.topBarWarning,
    showTopBarWarning: (state) => state.showTopBarWarning,
    pointWarning: (state) => state.pointWarning,
    getLastUsedCreditCard: (state) => state.lastUsedCreditCard,
    currentSignature: (state) => state.currentSignature,
    renewalFlowType: (state) => state.gtm.renewalFlowType,
  },
});
