import { useStore } from 'vuex';
import { computed } from 'vue';

const PERCENTAGE_TO_COMPLETE_LESSON = 95;

export default function useContent() {
  const store = useStore();
  const stripes = computed(() => store.getters['courses/stripes']);
  const userFollows = computed(() => store.getters['follow/userFollow']);
  const coursesFollow = computed(() => store.getters['courses/courseFollow']);

  const getProgressLesson = (lessonID) => {
    if (!userFollows.value || !Array.isArray(userFollows.value)) return 0;

    const lesson = userFollows.value.find((userFollow) => userFollow.lessonID == lessonID);
    if (lesson && lesson.contentFollowCompleted) return 100;
    return lesson ? Math.ceil(lesson.contentFollowPercentual) : 0;
  };

  const getCourseFiles = (courseLessons = []) => {
    if (!courseLessons || !Array.isArray(courseLessons)) return [];

    const files = courseLessons.filter((lesson) => lesson.lessonFiles?.length).map((lesson) => lesson.lessonFiles);

    return files.flat(2);
  };

  const getFollowedLessons = (courseID) => {
    if (!courseID) {
      return [];
    }

    return userFollows.value
      .filter((userFollow) => userFollow.courseID === courseID)
      .sort((prev, acc) => new Date(acc.contentFollowUpdatedAt) - new Date(prev.contentFollowUpdatedAt));
  };

  const getCourseFollow = (courseID) => {
    if (!courseID) {
      return {};
    }

    return coursesFollow.value.find((courseFollow) => {
      return courseFollow.courseID === courseID;
    });
  };

  const getIncompleteLesson = (courseFollowedLessons) => {
    if (!courseFollowedLessons) {
      return null;
    }

    return courseFollowedLessons.find((courseFollowedLesson) => {
      return (
        courseFollowedLesson.contentFollowPercentual < PERCENTAGE_TO_COMPLETE_LESSON &&
        !courseFollowedLesson.contentFollowCompleted
      );
    });
  };

  const getLessonIndex = (lessonID, courseLessons = []) => {
    const lessonIndexAdded = courseLessons.findIndex((lesson) => lesson.lessonID === lessonID) + 1;
    return lessonIndexAdded < 10 ? `0${lessonIndexAdded}` : lessonIndexAdded.toString();
  };

  const populateLessonsWithFollow = (courseLessons = [], courseFollowedLessons = []) => {
    if (!courseLessons.length || !courseFollowedLessons.length) {
      return [];
    }

    return courseLessons.map((lesson) => {
      const followLesson = courseFollowedLessons.find((courseFollowedLesson) => {
        return courseFollowedLesson.lessonID === lesson.lessonID;
      });

      return followLesson
        ? followLesson
        : {
            contentFollowCompleted: false,
            lessonID: lesson.lessonID,
          };
    });
  };

  const getRedirectLessonAction = (courseID, courseLessons = []) => {
    if (!courseID || !courseLessons.length) {
      return;
    }

    // No lessons follow the course
    const courseFollowedLessons = getFollowedLessons(courseID);
    if (!courseFollowedLessons.length) {
      return {
        slugStatus: 'start',
        text: 'Assistir agora',
        lesson: courseLessons[0],
      };
    }

    // Course is finished
    const courseFollow = getCourseFollow(courseID);
    if (courseFollow?.contentFollowPercentual > PERCENTAGE_TO_COMPLETE_LESSON) {
      return {
        slugStatus: 'restart',
        text: 'Assistir novamente',
        lesson: courseLessons[0],
      };
    }

    // Check if any courseFollow is not complete
    const incompleteLesson = getIncompleteLesson(courseFollowedLessons);
    if (incompleteLesson) {
      return {
        slugStatus: 'keep',
        text: 'Continue assistindo',
        lesson: incompleteLesson,
      };
    }

    // Get next lesson because all courseFollow is complete
    const lessonsWithFollow = populateLessonsWithFollow(courseLessons, courseFollowedLessons);
    const nextLessonWatch = lessonsWithFollow.find((lessonsFollow) => {
      return !lessonsFollow.contentFollowCompleted;
    });

    if (!nextLessonWatch) {
      return {
        slugStatus: 'start',
        text: 'Assistir agora',
        lesson: courseLessons[0],
      };
    }

    return {
      slugStatus: 'keep',
      text: 'Continue assistindo',
      lesson: nextLessonWatch,
    };
  };

  const flattenCourseLessons = (courseModules = []) => {
    if (!Array.isArray(courseModules) || !courseModules.length) return [];

    return courseModules
      .flatMap((currentModule) => currentModule.moduleEntities)
      .filter((entities) => entities?.lessonID);
  };

  const flattenCourseExtras = (courseExtras) => {
    if (!courseExtras || !Array.isArray(courseExtras)) return courseExtras;

    const flattenedCourseExtras = {};
    courseExtras.forEach((property) => {
      flattenedCourseExtras[property.key] = property.value;
    });

    return flattenedCourseExtras;
  };

  const getCouseStripe = (courseID) => {
    if (stripes.value.scheduled.includes(courseID)) return 'Em breve';
    if (stripes.value.lastPublished === courseID) return 'Lançamento';

    return '';
  };

  const formatCourseCard = (course) => {
    let author, interpretedBy, reverseImage, isLive;
    let type = 'layered';

    const extras = flattenCourseExtras(course?.courseExtras);

    if (course.courseGroup === 'FINBOOKS') {
      author = extras.bookAuthor;
      type = 'default';
      interpretedBy = course.courseCenter;
      reverseImage = course.courseMedias.thumb || course.courseMedias.poster;
    } else if (course.courseGroup === 'FINSESSIONS') {
      author = course.courseCenter;
      isLive = extras.isLive === 'true';
    } else {
      author = course.courseCenter;
    }

    return {
      type,
      subtitle: author,
      courseID: course.courseID,
      title: course.courseTitle,
      thumbnail: course.courseMedias.poster,
      stripe: getCouseStripe(course.courseID),
      thumbnailHover: course.courseGroup === 'FINBOOKS' ? course.courseMedias.thumb : undefined,
      description: course.courseGroup === 'FINBOOKS' ? `Interpretado por ${interpretedBy}` : course.courseDescription,
    };
  };

  const overwriteFinseriesType = (type = '') => {
    if (typeof type !== 'string') return type;

    return type.toLowerCase() === 'finseries' ? 'findocs' : type;
  };

  return {
    getProgressLesson,
    getCourseFiles,
    getRedirectLessonAction,
    flattenCourseLessons,
    formatCourseCard,
    getLessonIndex,
    overwriteFinseriesType,
  };
}
