import { createI18n } from 'vue-i18n';
import storeErrorDictionaryEN from '@/locales/en/store-error-dictionary.json';
import storeErrorDictionaryPT_BR from '@/locales/pt_BR/store-error-dictionary.json';

const vueI18n = createI18n({
  locale: 'pt_BR',
  fallbackLocale: 'en_US',
  messages: {
    pt_BR: { ...storeErrorDictionaryPT_BR },
    en_US: { ...storeErrorDictionaryEN },
  },
  missing: () => {
    return '';
  },
});

export default vueI18n;
