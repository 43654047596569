import usersHttp from '@/http/users';
import learningHttp from '@/http/lms';

const ENDPOINT = '/user';

/**
 * Module structure
 */
const state = {
  banners: [],
};

const getters = {
  banners: (state) => state.banners,
};

const mutations = {
  SET_BANNERS(state, banners) {
    state.banners = banners;
  },
};

const actions = {
  async updateUserSettings({}, settings) {
    try {
      await usersHttp.patch(`${ENDPOINT}/userSettings`, settings);
    } catch (error) {
      console.log('## GPRIM ## Refresh token error.');
    }
  },
  async getBanners({ commit }) {
    try {
      const { data: banners } = await learningHttp.get('/banners');
      commit('SET_BANNERS', banners.data);
    } catch (error) {
      commit('SET_BANNERS', []);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
