const state = {
  homeRedirectReason: {
    message: '',
    type: 'warning',
  },
  loginRedirectReason: null,
};

const getters = {
  homeRedirectReason: (state) => state.homeRedirectReason,
  loginRedirectReason: (state) => state.loginRedirectReason,
};

const mutations = {
  SET_HOME_REDIRECT_REASON(state, reason) {
    state.homeRedirectReason = reason;
  },
  SET_LOGIN_REDIRECT_REASON(state, reason) {
    state.loginRedirectReason = reason;
  },
};

const actions = {
  setHomeRedirectReason({ commit }, reason) {
    if (reason === null) {
      commit('SET_HOME_REDIRECT_REASON', { message: '', type: 'warning' });
      return;
    }

    const { message, type = 'warning' } = reason;

    commit('SET_HOME_REDIRECT_REASON', { message, type });
  },
  setLoginRedirectReason({ commit }, reason) {
    commit('SET_LOGIN_REDIRECT_REASON', reason);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
