import axios from 'axios';
import store from '@/store';
import { captureError } from '@/Utils/Sentry/captureErrorSentry';

const users = axios.create({
  baseURL: `${process.env.VUE_APP_CORE_BASE_URL}/users`,
  headers: {
    'Content-Type': 'application/json',
    'X-gprim-specnav': window.navigator.userAgent,
    'X-gprim-specdev': 'WEB',
    'X-gprim-specver': '2.2.1',
  },
});

users.interceptors.request.use(
  function (config) {
    const token = store.getters['auth/token'];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);

users.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (err) => {
    const hasError4xx = err?.response?.status === 401 || err?.response?.status === 403;
    captureError(err, hasError4xx ? 'log' : 'error');
    if (err.response && hasError4xx) {
      await store.dispatch('currentLogout');
    } else {
      return Promise.reject(err.response);
    }
  }
);

export default users;
