import * as Sentry from '@sentry/vue';
import { logoutUserMix } from '@/mixins';

export const init = (app, router) => {
  Sentry.init({
    app,
    environment: process.env.VUE_APP_STAGE,
    enabled: process.env.VUE_APP_STAGE === 'app',
    release: `finclass-webapp@${require('../../package.json').version}`,
    dsn: 'https://1327c15bc668456eafd62f239189fab8@o245296.ingest.us.sentry.io/4505284699619328',
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true,
      }),
    ],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.02,
    tracesSampleRate: 0,
    tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
    ignoreErrors: [
      '/pagead/viewthroughconversion/406018914/',
      'AxiosError: Request failed with status code 404',
      'AxiosError: Request failed with status code 409',
      'AxiosError: Request failed with status code 401',
    ],
    denyUrls: [
      /extensions\//i,
      /^chrome:\/\//i,
      /^chrome-extension:\/\//i,
      /pagead2\.googlesyndication\.com/i,
      /^safari:\/\//i,
      /^safari-extension:\/\//i,
      /^gtag\/\//i,
    ],
    beforeSend(event) {
      validateQuotaExceededError(event);
      return event;
    },
  });
};

const validateQuotaExceededError = (event) => {
  const values = event?.exception?.values || [];

  const isQuotaExceededError = values.some((value) => value.type === 'QuotaExceededError');

  if (isQuotaExceededError) {
    logoutUserMix.methods.logoutUser();
  }
};
