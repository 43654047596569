import { captureError } from '@/Utils/Sentry/captureErrorSentry';
import lmsClient from '@/http/lms';
import staticClient from '@/http/static';

const ENDPOINT = 'outputs';
const STATIC_FILE_ENDPOINT = '/platform-onboarding-settings.json';

const OUTPUTS = {
  onboarding: {
    kind: 'suitability',
    title: 'Perfil do usuário',
  },
};

/**
 * Module structure
 */
const state = {
  userOnboardingProfile: '',
  userOnboardingMeta: '',
};

const getters = {
  userOnboardingProfile: (state) => state.userOnboardingProfile,
  userOnboardingMeta: (state) => state.userOnboardingMeta,
};

const mutations = {
  SET_USER_ONBOARDING_PROFILE(state, profile) {
    state.userOnboardingProfile = profile;
  },
  SET_USER_ONBOARDING_META(state, meta) {
    state.userOnboardingMeta = meta || { outputDescription: '', outputPercentage: 10 };
  },
};

const actions = {
  async buildOnboardingOutput({ commit, state, dispatch, rootGetters }, create = false) {
    let data = {};
    let skipRequest = false;
    const userID = rootGetters['dataUser'].userID;
    const onboardingFollow = rootGetters['quiz/onboardingFollow'] || [];

    if (!Array.isArray(onboardingFollow) || !onboardingFollow.length) return;

    try {
      const response = await staticClient.get(STATIC_FILE_ENDPOINT);
      if (response.status !== 200) return;

      data = response.data;
    } catch (error) {
      console.log('## GPRIM ## Error on get static onboarding settings.', error);
      return;
    }

    const specificQuestions = [data.investimentKnowledgeQuestionID, data.financialObjectiveQuestionID];
    const filteredAnswers = onboardingFollow
      .filter((question) => specificQuestions.includes(question.questionID))
      .map(({ questionAnswer }) => (Array.isArray(questionAnswer) ? questionAnswer[0] : questionAnswer));

    const investimentKnowledge = data.investimentKnowledgeMapping[filteredAnswers[0]];
    const financialObjective = data.financialObjectiveMapping[filteredAnswers[1]];

    if (!investimentKnowledge || !financialObjective) {
      skipRequest = true;
      return;
    }

    const outputValue = `${financialObjective} ${investimentKnowledge}`;
    if (state?.userOnboardingProfile === outputValue) {
      skipRequest = true;
    }

    const output = {
      userID,
      outputKind: OUTPUTS.onboarding.kind,
      outputTitle: OUTPUTS.onboarding.title,
      outputValue,
      outputMeta: {
        outputDescription: data?.description[financialObjective],
        outputPercentage: data?.percentage[investimentKnowledge],
      },
    };

    commit('SET_USER_ONBOARDING_PROFILE', output.outputValue);
    commit('SET_USER_ONBOARDING_META', output.outputMeta);
    dispatch('sendN8nSuitabilityIntegration', { quizID: process.env.VUE_APP_ONBOARDING_QUIZ_ID });

    if (create && !skipRequest) {
      dispatch('createOutput', output);
    } else {
      return output;
    }
  },

  async getOnboardingOutput({ commit, state }) {
    const localUserOnboardingProfile = state.userOnboardingProfile;
    if (localUserOnboardingProfile) {
      return localUserOnboardingProfile;
    }

    try {
      const response = await lmsClient.get(`${ENDPOINT}/${OUTPUTS.onboarding.kind}`);

      if (response.data.data?.outputValue && response.data.data?.outputMeta) {
        commit('SET_USER_ONBOARDING_PROFILE', response.data.data.outputValue);
        commit('SET_USER_ONBOARDING_META', response.data.data.outputMeta);
      }

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async createOutput({}, payload) {
    try {
      const response = await lmsClient.post(`${ENDPOINT}`, payload);
      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  async sendN8nSuitabilityIntegration({}, { quizID }) {
    try {
      const response = await lmsClient.post(`/quizzes/n8n-integration/${quizID}`);

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },

  resetUserOnboardingProfile({ commit }) {
    commit('SET_USER_ONBOARDING_PROFILE', '');
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
