import { useRoute, useRouter } from 'vue-router';

export default function useNavigate() {
  const route = useRoute();
  const router = useRouter();

  function addQueryParams(queryParams) {
    return router.replace({
      name: route.name,
      query: { ...route.query, ...queryParams },
      params: route.params,
    });
  }

  function removeQueryParam(keyToRemove) {
    const { [keyToRemove]: removed, ...params } = route.query;

    return router.replace({
      name: route.name,
      query: { ...params },
      params: route.params,
    });
  }

  function to(routeName, params, queryParams, hash) {
    return router.push({
      name: routeName,
      query: { ...(router.currentRoute.value ? router.currentRoute.value.query : {}), ...queryParams },
      params,
      hash,
    });
  }

  function getQueryParam(queryParam) {
    if (!queryParam) return '';
    return route?.query?.[queryParam] || '';
  }

  function getRouteParam(routeParam) {
    if (!routeParam) return '';
    return route?.params?.[routeParam] || '';
  }

  function getRouteName() {
    return route.name;
  }

  return {
    to,
    getRouteName,
    getQueryParam,
    getRouteParam,
    addQueryParams,
    removeQueryParam,
  };
}
