<template>
  <div :class="['baseThinProgressBar', { hide: !isVisible }]">
    <div class="baseThinProgressBar__progress" :style="{ width: `${progressWidth}%` }"></div>
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'BaseThinProgressBar',
  props: {
    isVisible: { type: Boolean, required: true },
    current: { type: Number, required: true },
    total: { type: Number, required: true },
  },
  setup(props) {
    // By default, return 1% to show the start of the progress
    const progressWidth = computed(() => (props.current / props.total) * 100 || 1);

    return { progressWidth };
  },
};
</script>

<style lang="scss" scoped>
.baseThinProgressBar {
  width: 100%;
  height: 4px;
  background-color: $dark-500;
  backdrop-filter: blur(11px);
  border-radius: 100px;
  opacity: 1;
  transition: opacity 0.2s linear;

  &.hide {
    opacity: 0;
    pointer-events: none;
  }

  &__progress {
    width: 1%;
    height: 100%;
    background: $soul-light;
    background-clip: unset;
    -webkit-text-fill-color: transparent;
    border-radius: 100px;
    overflow: hidden;
    transition: all ease 2s;
  }
}
</style>
