<template>
  <div v-if="shouldShowTopBarWarning()" class="topBarWarning">
    <div @click="goToMySignaturePage" class="topBarWarning__message">
      <h6>{{ topBarWarningMessage }}</h6>
    </div>
    <button @click="closeTopBarWarning" class="topBarWarning__close">
      <img
        src="@/assets/img/close.svg"
        alt="Ícone simbolizando a ação de fechar o aviso que, ao ser clicado, remove a barra de notificação da página"
      />
    </button>
  </div>
</template>

<script>
import users from '@/http/users';
import { utils } from '@/mixins/utils.js';
import { mapActions, mapGetters } from 'vuex';
import { dataLayerMixin } from '@/mixins/dataLayerMixin';
import { TOP_BAR_WARNING_IDS } from '@/Utils/selectsValues';
import { captureError } from '@/Utils/Sentry/captureErrorSentry';

const { SIGNATURE, CREDIT_CARD } = TOP_BAR_WARNING_IDS;
const NUMBER_OF_DAYS_ALERT_ON_SUBSCRIPTION_EXPIRATION = -30;
const CREDIT_CARD_WARNING_MESSAGE = 'Você possui um aviso em Assinatura';

export default {
  name: 'TopBarWarning',
  mixins: [utils, dataLayerMixin],
  computed: {
    ...mapGetters(['currentSignature', 'topBarWarning', 'userIsLifetime']),
    topBarWarningMessage() {
      if (this.topBarWarning.includes(CREDIT_CARD)) {
        return CREDIT_CARD_WARNING_MESSAGE;
      } else if (this.topBarWarning.includes(SIGNATURE)) {
        if (this.currentSignature.remainingDays < NUMBER_OF_DAYS_ALERT_ON_SUBSCRIPTION_EXPIRATION) {
          return;
        }

        if (this.currentSignature.remainingDays === 0) {
          return `Sua assinatura expira HOJE. Renove sua assinatura`;
        }

        const daysMessage = this.currentSignature.remainingDays > 0 ? 'expirou há' : 'expira em';
        return `Sua assinatura ${daysMessage} ${Math.abs(
          this.currentSignature.remainingDays
        )} dias. Renove sua assinatura`;
      }

      return '';
    },
    topBarWarningDataLayerKey() {
      if (this.topBarWarning.includes(CREDIT_CARD)) {
        return 'changePaymentMethodStart';
      }

      if (this.topBarWarning.includes(SIGNATURE)) {
        return 'recurrencyOnStart';
      }

      return '';
    },
    appendOriginParamOnRedirect() {
      return this.topBarWarning.includes(CREDIT_CARD) && this.topBarWarningMessage === CREDIT_CARD_WARNING_MESSAGE;
    },
  },
  watch: {
    currentSignature: function (value) {
      if (!value?.isRecurrent && value?.remainingDays >= NUMBER_OF_DAYS_ALERT_ON_SUBSCRIPTION_EXPIRATION) {
        this.addPointWarningId(SIGNATURE);

        if (!this.userIsLifetime) {
          this.addTopBarWarningId(SIGNATURE);
        }
      } else {
        this.removePointWarningId(SIGNATURE);
        this.removeTopBarWarningId(SIGNATURE);
      }
    },
  },
  created() {
    this.getPayment();
  },
  methods: {
    ...mapActions([
      'addPointWarningId',
      'addTopBarWarningId',
      'removePointWarningId',
      'removeTopBarWarningId',
      'setCurrentSignature',
      'setLastUsedCreditCard',
      'setRenewalFlowType',
      'updateShowTopBarWarning',
    ]),
    ...mapActions('subscription', ['setHasTopBarWarningCreditCardAlert']),
    shouldShowTopBarWarning() {
      const shouldShow = Array.isArray(this.topBarWarning) && this.topBarWarning.length && this.topBarWarningMessage;

      this.updateShowTopBarWarning(shouldShow);

      return shouldShow;
    },
    getPayment() {
      users
        .get('/ecommerce/readPayment')
        .then((response) => {
          if (!response?.data?.data) {
            return;
          }

          const { subscription, subscription_cycle, transactions } = response.data.data;

          if (!Object.keys(subscription).length || !Object.keys(subscription_cycle).length) {
            return;
          }

          const lastTransaction = transactions?.[transactions?.length - 1];

          const remainingDays = this.getRemainingDaysFromTodayUntil(subscription_cycle?.endDate);
          const subscriptionPeriod = this.getSubscriptionPeriod(
            subscription?.intervalCount,
            subscription?.intervalType
          );

          this.setCurrentSignature({
            subscriptionID: subscription?.subscriptionID,
            paymentGateway: subscription?.paymentGateway,
            paymentMethod: subscription?.paymentMethod,
            isRecurrent: subscription?.isRecurrent,
            cycleStartDate: subscription_cycle?.startDate,
            cycleEndDate: subscription_cycle?.endDate,
            isRenewed: subscription_cycle?.cycleCount > 1 || subscription?.meta?.isAutoRenewed,
            isExpired: remainingDays >= 0,
            remainingDays,
            externalPlanID: subscription?.externalPlanID, // useful for identifying XP users
            installments: lastTransaction?.installments,
            subscriptionPeriod,
            orderID: subscription?.orderID,
            hasError: false,
          });
        })
        .catch((error) => {
          captureError(error, 'error');
          this.setCurrentSignature({
            hasError: true,
          });
        });
    },
    closeTopBarWarning() {
      if (this.topBarWarning.includes(CREDIT_CARD)) {
        this.removeTopBarWarningId(CREDIT_CARD);
      }
      if (this.topBarWarning.includes(SIGNATURE)) {
        this.removeTopBarWarningId(SIGNATURE);
      }
    },
    goToMySignaturePage() {
      this.setRenewalFlowType('TOP_BAR_WARNING');

      this.pushToDataLayer(this.topBarWarningDataLayerKey, {
        flow: 'TOP_BAR_WARNING',
        step: 'TOP_BAR_WARNING_HEADER',
        message: this.topBarWarningMessage,
      });

      if (this.appendOriginParamOnRedirect) {
        this.setHasTopBarWarningCreditCardAlert(true);
      }

      return this.$router.push({ name: 'MySignature' });
    },
  },
};
</script>

<style lang="scss" scoped>
.topBarWarning {
  z-index: 101;
  width: 100%;
  min-width: 100%;
  height: $topBarWarningHeight;
  min-height: $topBarWarningHeight;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: $dark-900;
}

.topBarWarning__message {
  max-width: 222px;
  height: 24px;
  min-height: 24px;
  margin: 0 auto;
  padding: 4px 12px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-radius: 60px;
  background-color: $dark-red;
  cursor: pointer;

  > h6 {
    font-size: 0.75rem;
    font-weight: 700;
    color: $white;
  }
}

.topBarWarning__close {
  min-width: 32px;
  min-height: 32px;
  top: 12px;
  right: 16px;
  cursor: pointer;
}

@media (min-width: 768px) {
  .topBarWarning__message {
    max-width: 340px;
  }
}
</style>
