import Toast, { POSITION } from 'vue-toastification';

import 'vue-toastification/dist/index.css';

const options = {
  position: POSITION.BOTTOM_CENTER,
  maxToasts: 3,
  newestOnTop: true,
  pauseOnFocusLoss: false,
  filterBeforeCreate: (toast, toasts) => {
    if (toasts.filter((t) => t.type === toast.type).length !== 0) {
      // Returning false discards the toast
      return false;
    }
    // You can modify the toast if you want
    return toast;
  },
};

export default { instance: Toast, options };
