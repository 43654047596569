import axios from 'axios';
import { useToast } from 'vue-toastification';
import useErrorMessages from '@/composables/useErrorMessages';

const toast = useToast();
const errorMessages = useErrorMessages();

const store = axios.create({
  baseURL: process.env.VUE_APP_STORE_CLIENT_URL,
  headers: {
    'Content-Type': 'application/json',
    'X-gprim-specnav': window.navigator.userAgent,
    'X-gprim-specdev': 'WEB',
    'X-gprim-specver': '1.0.0',
    Service: 'finclass',
  },
});

store.interceptors.response.use(
  async (response) => {
    return Promise.resolve(response.data);
  },
  async (error) => {
    const message = errorMessages.getErrorMessage(error?.response?.data?.error || error?.code);

    if (message) {
      toast.error(message);
    }

    return Promise.reject(error?.response?.data);
  }
);
export default store;
