<template>
  <Teleport to="head">
    <component :is="'script'" data-gtm-container="institutional-site">
      (function(w, d, s, l, i) { w[l] = w[l] || []; w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
      var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async =
      true; j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f); })(window,
      document, 'script', 'dataLayer', 'GTM-MZ447LR');
    </component>
  </Teleport>

  <Teleport to="body">
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe
        title="institutional-site"
        src="https://www.googletagmanager.com/ns.html?id=GTM-MZ447LR"
        height="0"
        width="0"
        style="display: none; visibility: hidden"
      ></iframe>
    </noscript>
    <!-- End Google Tag Manager (noscript) -->
  </Teleport>
</template>

<script>
export default {
  name: 'InstitutionalWebsiteContainer',
};
</script>
