import { captureError } from '@/Utils/Sentry/captureErrorSentry';
import lmsClient from '@/http/lms';

const ENDPOINT = '/courses/search';

/**
 * Module Structure
 */
const state = {
  searchedTerm: '',
  searchResults: [],
};

const getters = {
  searchedTerm: (state) => state.searchedTerm,
  searchResults: (state) => state.searchResults,
};

const mutations = {
  SET_SEARCHED_TERM(state, searchedTerm) {
    state.searchedTerm = searchedTerm;
  },
  SET_SEARCH_RESULTS(state, searchResults) {
    state.searchResults = searchResults;
  },
};

const actions = {
  async getSearchResults({ commit }, value) {
    try {
      commit('TOOGLELOADERGENERAL', true, { root: true });
      commit('SET_SEARCHED_TERM', value);
      const response = await lmsClient.get(ENDPOINT, { params: { search: value, page: 1, perPage: 20 } });
      response.data.data = response.data.data.filter((item) => item.courseSlug !== 'carteira-finclass');
      commit('SET_SEARCH_RESULTS', response.data.data || []);
      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    } finally {
      commit('TOOGLELOADERGENERAL', false, { root: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
