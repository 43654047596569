export const courseGroupAssignments = {
  FINDOCS: {
    courseGroupLabel: 'finseries',
    courseDetailsPage: 'ExpertDetails',
  },
  FINBOOKS: {
    courseGroupLabel: 'finbooks',
    courseDetailsPage: 'ExpertDetails',
  },
  FINCLASSES: {
    courseGroupLabel: 'finclasses',
    courseDetailsPage: 'ExpertDetails',
  },
  FINSESSIONS: {
    courseGroupLabel: 'finsessions',
    courseDetailsPage: 'ExpertDetails',
  },
  FINJOURNEYS: {
    courseGroupLabel: 'finjourneys',
    courseDetailsPage: 'ExpertDetails',
  },
  FINMENTORSHIPS: {
    courseGroupLabel: 'finmentorships',
    courseDetailsPage: 'MentorshipDetails',
  },
};

export const FILE_TYPES_PROPERTIES = {
  xlsx: 'Planilhas',
  pdf: 'PDF',
};
