import http from '@/http/users';

const state = {
  queryMaterials: '',
  showCases: [],
};

const getters = {
  getQueryMaterials: (state) => state.queryMaterials,
  getShowcases: (state) => state.showCases,
};

const mutations = {
  SET_QUERY_MATERIALS(state, queryMaterials) {
    state.queryMaterials = queryMaterials;
  },
  GET_SHOW_CASE(state, showCases) {
    state.showCases = showCases;
  },
};

const actions = {
  setQueryMaterials({ commit }, payload) {
    commit('SET_QUERY_MATERIALS', payload);
  },
  async getShowcase({ commit }) {
    try {
      const response = await http.get('/ecommerce/mentorship-offers');
      commit('GET_SHOW_CASE', response.data.data);
    } catch (error) {
      commit('GET_SHOW_CASE', []);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
