import createStore from '@/store';
import { utils } from '@/mixins/utils';
import { courseGroupAssignments } from '@/Utils/constants/courses';
import { createRouter, createWebHistory } from 'vue-router';
import useSessionStorage from '@/composables/useSessionStorage';
import { FINCLASS_TERMS_OF_USE, FINCLASS_PRIVACY_POLICY } from '@/Utils/constants/externalLinks';

const routes = [
  {
    path: '/change-payment/:userID',
    name: 'ChangePayment',
    component: () => import(/* webpackChunkName: "changePayment" */ '../views/ChangePayment.vue'),
  },
  {
    path: '/privacy-policy/:id',
    name: 'PrivacyPolicy',
    meta: {
      privacyPolicy: true,
    },
    beforeEnter(to, _, next) {
      const idToUrlMap = {
        1: FINCLASS_TERMS_OF_USE,
        7: FINCLASS_PRIVACY_POLICY,
      };
      const id = to.params.id;
      const redirectUrl = idToUrlMap[id];
      if (redirectUrl) {
        window.location.href = redirectUrl;
      } else {
        next({ name: 'NotFound' });
      }
    },
  },
  {
    path: '/:catchAll(.*)*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "notFound" */ '../views/NotFound.vue'),
  },
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue'),
  },
  {
    path: '/watch/:type/:idCourse/:idLesson?',
    name: 'Watch',
    component: () => import(/* webpackChunkName: "watchfinclasses" */ '../views/Watch.vue'),
    beforeEnter(to, from) {
      if (to.params.type === 'finsessions') {
        router.push({
          name: 'FinsessionsLive',
          params: { id: to.params.idCourse },
        });

        return;
      }

      if (to.params.type === courseGroupAssignments.FINMENTORSHIPS.courseGroupLabel) {
        router.push({
          name: 'WatchMentorship',
          params: { idCourse: to.params.idCourse, idLesson: to.params.idLesson },
        });
      }
    },
  },
  {
    path: '/watch-mentorship/:idCourse/:idLesson?',
    name: 'WatchMentorship',
    component: () => import(/* webpackChunkName: "watchfinclasses" */ '../views/mentorship/Watch/WatchMentorship.vue'),
    beforeEnter(to, from) {
      if (!to?.params?.idCourse) {
        return router.push({ name: 'NotFound' });
      }
      router.push({ name: 'ExpertDetails', params: { id: to.params.idCourse } });
    },
  },
  {
    path: '/finsessions/:id',
    name: 'FinsessionsLive',
    component: () => import(/* webpackChunkName: "finsessions" */ '../views/finsessions/FinsessionsLive.vue'),
  },
  {
    path: '/finsessions',
    name: 'Finsessions',
    component: () => import(/* webpackChunkName: "finsessions" */ '../views/finsessions/Finsessions.vue'),
  },
  {
    path: '/finclasses',
    name: 'Finclasses',
    component: () => import(/* webpackChunkName: "courseGroupContent" */ '../views/CourseGroupContent.vue'),
    meta: {
      mainHeaderActiveName: 'Learn',
    },
  },
  {
    path: '/finbooks',
    name: 'Finbooks',
    component: () => import(/* webpackChunkName: "courseGroupContent" */ '../views/CourseGroupContent.vue'),
    meta: {
      mainHeaderActiveName: 'Learn',
    },
  },
  {
    path: '/finseries',
    name: 'Finseries',
    component: () => import(/* webpackChunkName: "courseGroupContent" */ '../views/CourseGroupContent.vue'),
    meta: {
      mainHeaderActiveName: 'Learn',
    },
  },
  {
    path: '/finjourneys',
    name: 'Finjourneys',
    component: () => import(/* webpackChunkName: "courseGroupContent" */ '../views/CourseGroupContent.vue'),
  },
  {
    path: '/preview/report',
    name: 'PreviewReport',
    meta: {
      preview: true,
    },
    component: () => import(/* webpackChunkName: "courseGroupContent" */ '../views/preview/ReportPreview.vue'),
  },
  {
    path: '/finmentorships',
    name: 'Finmentorships',
    component: () => import(/* webpackChunkName: "courseGroupContent" */ '../views/CourseGroupContent.vue'),
  },
  {
    path: '/expert-details/:id',
    name: 'ExpertDetails',
    component: () => import(/* webpackChunkName: "expertdetails" */ '../views/ExpertDetails.vue'),
    meta: {
      mainHeaderActiveName: 'Learn',
    },
    beforeEnter(to) {
      if (to?.params?.type && to?.params?.type === courseGroupAssignments.FINMENTORSHIPS.courseGroupLabel) {
        router.push({ name: 'MentorshipDetails', params: { id: to.params.id }, query: { tab: to.query.tab || '' } });
      }
    },
  },
  {
    path: '/mentorship-cockpit/:id',
    name: 'MentorshipDetails',
    component: () => import(/* webpackChunkName: "mentorshipdetails" */ '../views/MentorshipDetails.vue'),
    meta: {
      mainHeaderActiveName: 'Learn',
    },
    beforeEnter(to) {
      if (!to?.params?.id) {
        return router.push({ name: 'NotFound' });
      }
      router.push({ name: 'ExpertDetails', params: { id: to.params.id } });
    },
  },
  {
    path: '/mentorship/:slug',
    name: 'Mentorship',
    component: () =>
      import(/* webpackChunkName: "mentorship" */ '../views/mentorship/Apresentation/ApresentationMentorship.vue'),
    meta: {
      mainHeaderActiveName: 'Learn',
    },
  },
  {
    path: '/thank-you/:slug',
    name: 'ThankYouPage',
    component: () => import(/* webpackChunkName: "mentorship" */ '../views/mentorship/ThankYou/ThankYouPage.vue'),
  },
  {
    path: '/trail/:pathID',
    name: 'TrailDetails',
    component: () => import(/* webpackChunkName: "trailDetails" */ '../views/TrailDetails.vue'),
  },
  {
    path: '/search/:termToSearch?',
    name: 'Search',
    component: () => import(/* webpackChunkName: "search" */ '../views/Search.vue'),
  },
  {
    path: '/progress',
    name: 'Progress',
    component: () => import(/* webpackChunkName: "progress" */ '../views/Progress.vue'),
  },
  {
    path: '/my-profile/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "profile" */ '../views/MyProfile.vue'),
  },
  {
    path: '/my-profile/personal-data',
    name: 'PersonalData',
    component: () => import(/* webpackChunkName: "personalData" */ '../views/my-profile/PersonalData.vue'),
  },
  {
    path: '/my-profile/my-signature',
    name: 'MySignature',
    component: () => import(/* webpackChunkName: "mySignature" */ '../views/MySignature.vue'),
  },
  {
    path: '/offer-checkout/:slug',
    name: 'OfferCheckout',
    component: () => import(/* webpackChunkName: "offerCheckout" */ '../views/OfferCheckout.vue'),
  },
  {
    path: '/ticket-generated',
    name: 'TicketGenerated',
    component: () => import(/* webpackChunkName: "ticketGenerated" */ '../views/TicketGenerated.vue'),
  },
  {
    path: '/help-center',
    name: 'HelpCenter',
    component: () => import(/* webpackChunkName: "helpCenter" */ '../views/HelpCenter.vue'),
  },
  {
    path: '/login/:email?',
    name: 'Login',
    component: () => import(/* webpackChunkName: "lightLogin" */ '../views/LightLogin.vue'),
    meta: {
      public: true,
    },
    beforeEnter: (to, from) => {
      const { source, ...rest } = from.query;
      if (source && source === 'PARTNER') {
        router.push({ name: 'CompleteProfile', query: { ...rest, form: 2 } });
      }
    },
  },
  {
    path: '/complete-profile',
    name: 'CompleteProfile',
    component: () => import(/* webpackChunkName: "completeProfile" */ '../views/CompleteProfile.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/kinvo-finclass',
    name: 'KinvoCode',
    component: () => import(/* webpackChunkName: "kinvoCode" */ '../views/partners/KinvoCode.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/d/short-checkout/:slug?',
    name: 'ShortCheckout',
    component: () => import(/* webpackChunkName: "lightRegister" */ '../views/LightRegister.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/d/short-checkout/:slug/:userID',
    name: 'ShortCheckoutPayment',
    component: () => import(/* webpackChunkName: "lightPayment" */ '../views/LightPayment.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/d/up/:originalSlug/:slug',
    name: 'Upsell',
    component: () => import(/* webpackChunkName: "upsell" */ '../views/Upsell.vue'),
  },
  {
    path: '/short-checkout',
    name: 'ShortCheckoutDeprecated',
    component: () => import(/* webpackChunkName: "lightRegister" */ '../deprecated/views/LightRegister.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/short-checkout/:userID',
    name: 'ShortCheckoutPaymentDeprecated',
    component: () => import(/* webpackChunkName: "lightPayment" */ '../deprecated/views/LightPayment.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/up/:productID',
    name: 'UpsellDeprecated',
    component: () => import(/* webpackChunkName: "upsell" */ '../deprecated/views/Upsell.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import(/* webpackChunkName: "forgotPassword" */ '../views/ForgotPassword.vue'),
    meta: {
      public: true,
    },
  },
  {
    path: '/finclub',
    alias: '/finclub',
    name: 'Finclub',
    component: () => import(/* webpackChunkName: "finclub" */ '../views/Finclub.vue'),
    meta: {
      mainHeaderActiveName: 'Finclub',
    },
  },
  {
    path: '/carteira/:seriesSlug/:slug',
    name: 'SpitiSerieReport',
    component: () => import('../views/SpitiSerieReport.vue'),
    meta: {
      mainHeaderActiveName: 'SpitiSerieReport',
    },
  },
  {
    path: '/carteira/:slug',
    name: 'SpitiSerieDetail',
    component: () => import('../views/SpitiSerieDetails.vue'),
    meta: {
      mainHeaderActiveName: 'SpitiSerieDetail',
    },
  },
];

const router = createRouter({
  history: createWebHistory('/'),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const keepSavedPosition = utils.methods.keepSavedPosition({ ...to.query, ...from.query });

    if (to.hash) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve({ el: to.hash, behavior: 'smooth' });
        }, 1000);
      });
    } else if (keepSavedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});

router.beforeEach((to, from, next) => {
  const userId = createStore.getters.dataUser?.userID;
  if (userId) {
    const dataLayer = window.dataLayer || [];
    dataLayer.push({ event: 'virtualPageView', userId });
  }

  if (to.meta.privacyPolicy || to.meta.preview) {
    return next();
  }

  if (to.name === 'NotFound') {
    return next();
  }

  if (!to.meta.public && !createStore.state.auth.token) {
    const sessionStorage = useSessionStorage();
    if (!!to.name) {
      sessionStorage.addRedirectAttempt(to.name, to.params, to.query);
    }

    return next({ path: '/login' });
  }

  if (to.meta.public && createStore.state?.auth?.token) {
    return next({ path: '/' });
  }

  next();
});

export default router;
