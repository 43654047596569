<template>
  <template v-if="isLogged && !loaderHome && canDisplay">
    <TopBarWarning />
    <MainHeader />
    <OnboardingModalManager />
  </template>

  <AuthenticatedAreaContainer v-if="isLogged" />
  <InstitutionalWebsiteContainer v-else />

  <div id="app-main">
    <router-view :key="$route.path" v-slot="{ Component }">
      <Transition name="fade" mode="out-in">
        <component :is="Component"></component>
      </Transition>
    </router-view>
  </div>

  <MainFooter v-if="!loaderHome && isLogged && canDisplay" />

  <Transition name="fade">
    <LoaderLogin v-if="loaderHome" />
  </Transition>
</template>

<script setup>
import { onBeforeMount, computed, defineAsyncComponent } from 'vue';
import { useStore } from 'vuex';
import LoaderLogin from '@/components/LoaderLogin';
import useNavigate from '@/composables/useNavigate';
import TopBarWarning from '@/components/TopBarWarning';
import MainHeader from '@/components/common/MainHeader';
import { PAGE_NAMES_TO_NOT_DISPLAY } from '@/Utils/selectsValues';
import AuthenticatedAreaContainer from '@/components/gtm/AuthenticatedAreaContainer';
import InstitutionalWebsiteContainer from '@/components/gtm/InstitutionalWebsiteContainer';

const MainFooter = defineAsyncComponent(() => import('@/components/common/MainFooter'));
const OnboardingModalManager = defineAsyncComponent(() => import('@/components/modals/OnboardingModalManager'));

const store = useStore();
const { getRouteName } = useNavigate();

const loaderHome = computed(() => store.getters['loaderHome']);
const isLogged = computed(() => store.getters['auth/isLogged']);
const canDisplay = computed(() => !PAGE_NAMES_TO_NOT_DISPLAY.includes(getRouteName()));

onBeforeMount(() => {
  setTimeout(() => {
    const acceptAllCookiesButton = document.getElementById('c-p-bn');
    const customizeCookiesButton = document.getElementById('c-s-bn');

    if (acceptAllCookiesButton && customizeCookiesButton) {
      acceptAllCookiesButton.setAttribute('tabindex', '-1');
      customizeCookiesButton.setAttribute('tabindex', '-1');
    }
  }, 1500);
});
</script>

<style lang="scss">
@import './assets/scss/_fonts.scss';

* {
  list-style: none;
  text-decoration: none;
  margin: 0;
  padding: 0;
  text-align: left;
  border: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  font-family: 'Proxima Nova Regular', Verdana, Tahoma, sans-serif;
  color: #ffffff;
}
#app {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
#app-main {
  width: 100%;
  flex: 1;
}
input,
button,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
}
button {
  cursor: pointer;
}
img {
  display: block;
  max-width: 100%;
}
a {
  display: block;
}
html {
  font-size: 1rem;
}
html,
body {
  background-color: $dark-900;

  .container {
    background-color: unset !important;
  }
}
h1 {
  font-size: 3.5rem;
  line-height: 115%;
  font-weight: bold;
}
h2 {
  font-weight: bold;
  font-size: 2rem;
  line-height: 115.5%;
}
h3 {
  font-weight: bold;
  font-size: 1.5rem;
  line-height: 115%;
}
h4 {
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 115%;
}
h5 {
  font-weight: normal;
  font-size: 1.125rem;
  line-height: 145%;
  letter-spacing: -0.005em;
}
small {
  font-size: 1rem;
  line-height: 145%;
  letter-spacing: -0.005em;
}
.grecaptcha-badge {
  visibility: hidden;
}
.title {
  position: relative;
  z-index: 1;
  font-weight: 600;

  &:before {
    content: '';
    width: 28px;
    height: 28px;
    position: absolute;
    top: -2px;
    left: -20px;
    z-index: -1;
  }
  span {
    color: #00e7f9;
    font-weight: bold;
  }
}
.container {
  width: 100%;
  max-width: 1310px;
  padding: 0 15px;
  margin: 0 auto;
  @media (max-width: 1300px) {
    max-width: 1200px;
  }
  @media (max-width: 1200px) {
    width: 100%;
    padding: 0px 15px;
  }
}
.s-perfil {
  margin-top: 5.5rem;
  padding-top: 4.5rem;
  padding-bottom: 7.625rem;
  background: url('./assets/img/bg-profile.png') no-repeat top right;
}
.s-perfil .container {
  display: grid;
  grid-template-columns: 360px 1fr;
  grid-gap: 80px;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.swiper-pagination {
  position: relative;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  opacity: 1;
  width: 16px;
  border-radius: 130px;
}
.swiper-pagination .swiper-pagination-bullet:first-child {
  margin-left: 0px;
}
.swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0px;
}
.modal .modal-body form input,
.modal .modal-body form textarea {
  max-width: 100%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.35s ease;
}
.fade-enter-from,
.fade-leave-active {
  opacity: 0;
}
input[type='file'],
input[type='file']::-webkit-file-upload-button {
  cursor: pointer;
}
</style>
