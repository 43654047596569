import store from '@/http/store';

const state = {
  offers: {},
  currentOfferSlug: '',
};

const getters = {
  getOffer: (state) => (offerSlug) => state.offers[offerSlug],
  getCurrentOfferSlug: (state) => state.currentOfferSlug,
};

const mutations = {
  SET_OFFER(state, { offerSlug, offer }) {
    state.offers[offerSlug] = Object.assign(state.offers[offerSlug] || {}, offer);
  },
  SET_CURRENT_OFFER_SLUG(state, { offerSlug }) {
    state.currentOfferSlug = offerSlug;
  },
};

const actions = {
  async offerRead({ commit }, { offerSlug }) {
    try {
      const response = await store.get(`offers/offerSlug/${offerSlug}`);
      commit('SET_OFFER', { offerSlug: offerSlug, offer: response.data });

      if (response.data.offer.offerUpsells.length) {
        for (const offer of [...response.data.offer.offerUpsells, ...response.data.offer.offerBumps]) {
          commit('SET_OFFER', { offerSlug: offer.offerSlug, offer: offer });
        }
      }
    } catch (error) {
      return error;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
