<template>
  <header
    v-click-outside="closeLearnOptions"
    :class="{
      'show-search': showSearch,
      'fixed-header': header,
      'distance-from-top': showTopBarWarning && !menuIsOpen,
    }"
  >
    <main :class="{ 'main-selected': showLearnOptions }">
      <div class="container">
        <router-link @click="closeLearnOptions" :to="{ name: 'Home' }">
          <img src="@/assets/img/finclass-header.svg" class="logo" alt="" />
        </router-link>
        <nav>
          <ul class="gtm-menu">
            <li>
              <a
                @click="toggleLearnOptions('learn')"
                @mouseover="showLearnOptions = true"
                :class="{ active: mainHeaderActiveName === 'Learn' }"
              >
                <img src="@/assets/img/icon-header-learn.svg" alt="Aprenda" />
                Aprenda
                <div :class="{ 'menu__new-highlight header': lastPublishedFinsession?.liveNow }"></div>
              </a>
            </li>
            <li>
              <router-link
                @click="closeLearnOptions"
                :to="{ name: 'Finclub' }"
                :class="{ active: mainHeaderActiveName === 'Finclub' }"
              >
                <img src="@/assets/img/icon-header-community.svg" alt="Comunidade" />
                Comunidade
              </router-link>
            </li>
            <li>
              <router-link
                @click="closeLearnOptions"
                :to="{ name: 'SpitiSerieDetail', params: { slug: '10830e24-6830-40c1-bc23-699b060ec73e' } }"
                :class="{ active: mainHeaderActiveName === 'SpitiSerieDetail' }"
              >
                <img src="@/assets/img/icon-invista.svg" alt="Carteira" />
                Carteira
              </router-link>
            </li>
          </ul>
          <div>
            <button class="btn-search" id="js-btn-search" @click="showSearch = !showSearch">
              <img src="@/assets/img/icon-search.svg" alt="" />
            </button>
            <UserDropDown />
            <a id="js-open-menu" class="menu-button" @click="toggleMenu">
              <i class="menu-icon"></i>
            </a>
          </div>
        </nav>
      </div>
    </main>

    <FadeAnimation>
      <div class="learnOptions" v-show="showLearnOptions" @mouseleave="closeLearnOptions">
        <div class="learnOptions__content container">
          <ul>
            <li
              :class="{ 'learnOptions__content-blocked': option.active === false }"
              v-for="option in navigateLessonsOptions"
              :key="option.title"
              @click="redirectToPath(option.name, option.params)"
            >
              <div class="learnOptions__content-icon">
                <img :src="option.icon" alt="Opções" />
              </div>
              <div class="learnOptions__content-text">
                <div class="learnOptions__content-text--header">
                  <span>{{ option.title }}</span>
                  <FeatureTag v-if="option.newFeature" text="Novo" size="large" />
                  <div
                    v-if="option.name == 'Finsessions'"
                    :class="{ 'menu__new-highlight': lastPublishedFinsession?.liveNow }"
                  ></div>
                  <FeatureTag v-if="option.releaseSoon" text="Em breve" size="large" color="dark-600" />
                </div>
                <p>{{ option.description }}</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </FadeAnimation>

    <SearchBarHeader @clicked="closeSearch" />
    <MenuMobile @toggle-menu="toggleMenu" />
  </header>
</template>

<script setup>
import { ref, computed, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import FeatureTag from '@/components/FeatureTag';
import useNavigate from '@/composables/useNavigate';
import UserDropDown from '@/components/UserDropDown';
import MenuMobile from '@/components/common/MenuMobile';
import SearchBarHeader from '@/components/SearchBarHeader';
import { navigateLessonsOptions } from '@/Utils/selectsValues';
import FadeAnimation from '@/components/animations/FadeAnimation';

const FINSESSIONS_ROUTER_NAME = 'FinsessionsLive';
const NAVIGATE_OPTION_EXCEPTION_NAME = 'Finsessions';

const store = useStore();
const route = useRoute();
const navigate = useNavigate();

const header = ref(false);
const menuIsOpen = ref(false);
const showSearch = ref(false);
const showLearnOptions = ref(false);
const showLearnOptionsClick = ref(0);

const showTopBarWarning = computed(() => store.getters['showTopBarWarning']);
const mainHeaderActiveName = computed(() => route.meta.mainHeaderActiveName);
const lastPublishedFinsession = computed(() => store.getters['courses/lastPublishedFinsession']);

window.addEventListener('scroll', () => {
  header.value = window.scrollY > 50;
});

const closeSearch = (value) => {
  showSearch.value = value;
};

const toggleMenu = () => {
  document.querySelector('html').classList.toggle('menu-opened');
  menuIsOpen.value = !menuIsOpen.value;
};

const closeLearnOptions = () => {
  showLearnOptions.value = false;
  showLearnOptionsClick.value = 0;
};

const redirectToPath = (routerName, routerParams) => {
  let __routerName = routerName;
  let __routerParams = routerParams;

  const { liveNow, id } = lastPublishedFinsession.value;

  if (routerName === NAVIGATE_OPTION_EXCEPTION_NAME && liveNow && id) {
    __routerName = FINSESSIONS_ROUTER_NAME;
    __routerParams = { id: lastPublishedFinsession.value.id };
  }

  closeLearnOptions();
  navigate.to(__routerName, __routerParams);
};

const toggleLearnOptions = () => {
  if (showLearnOptionsClick.value === 0) {
    showLearnOptions.value = true;
    showLearnOptionsClick.value++;
  } else {
    closeLearnOptions();
  }
};

onBeforeMount(() => {
  store.dispatch('outputs/getOnboardingOutput');
});
</script>

<style lang="scss" scoped>
header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 102;

  &.distance-from-top {
    top: $topBarWarningHeight;
    transition: all ease-in-out 0.3s;

    > main {
      background-image: linear-gradient(to bottom, $dark-800, transparent);
    }
  }

  main {
    position: relative;
    z-index: 1;
    height: 88px;
    background-color: transparent;
    background-image: linear-gradient(to bottom, $dark-900, transparent);
    transition: background-color 0.3s ease-out;

    &.main-selected {
      background-color: $dark-800;
    }

    .logo {
      position: relative;
      z-index: 2;
    }
  }

  &.show-search {
    pointer-events: all;

    main {
      background: rgba(32, 34, 39, 0.8);
      backdrop-filter: blur(80px);
    }

    .search-input {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }
  }

  &.fixed-header {
    main {
      background-color: rgba($dark-900, 0.95);
    }
  }

  .container {
    display: flex;
    align-items: center;
    height: 100%;

    a {
      align-items: center;
    }
  }

  nav {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 6rem;

    ul {
      display: flex;
      align-items: center;
      margin-right: 20px;

      li {
        margin-left: 3rem;
        cursor: pointer;

        &:first-child {
          margin-left: 0px;
        }

        a,
        button {
          position: relative;
          display: flex;
          font-weight: 600;
          font-size: 1.125rem;
          line-height: 115%;
          letter-spacing: -0.005em;
          color: $white;
          opacity: 0.7;
          transition: opacity 0.3s;

          > img {
            margin-right: 1rem;
          }

          &::before {
            content: '';
            height: 2px;
            background-color: $spirit-stone;
            border-radius: 6px 6px 0px 0px;
            position: absolute;
            bottom: -33px;
            left: 0;
            width: 0%;
            transition: width 0.3s;
          }

          &::after {
            content: attr(data-text);
            display: block;
            margin-left: 0.75rem;
            padding: 0 0.375rem;
            border-radius: 6px;
            font-size: 12px;
            color: $dark-900;
            font-weight: 600;
            background: $spirit-stone;
            line-height: 16.8px;
            font-family: 'Proxima Nova Bold';
          }

          &:hover {
            opacity: 1;

            &:before {
              width: 100%;
            }
          }

          &.active {
            opacity: 1;

            &:before {
              width: 100%;
            }
          }
        }
      }
    }

    > div {
      min-width: fit-content;
      display: flex;
      align-items: center;

      .btn-search {
        width: 2rem;
        height: 2rem;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        margin-right: 2rem;
        transition: transform 0.3s;

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }

  .learnOptions {
    background-color: $dark-800;

    &__content {
      height: 19rem;

      > ul {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 3rem;
      }

      > ul li {
        position: relative;
        cursor: pointer;
        display: flex;
        transition: all 0.3s;
        padding: 1rem;

        &:hover {
          background-color: $dark-700;
          border-radius: 0.5rem;
        }
      }

      &-icon {
        min-width: 3rem;
        height: 3rem;
        margin-right: 1rem;
        border-radius: 3rem;
        background-color: $dark-700;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-text {
        > p {
          margin-top: 0.5rem;
          color: $dark-100;
        }

        &--header {
          display: flex;
          align-items: center;
          gap: 0.5rem;

          > span {
            font-size: 1rem;
            font-weight: bold;
          }
        }
      }

      &-blocked {
        opacity: 0.4;
        pointer-events: none;
      }
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  .menu__new-highlight {
    margin-left: 1rem;
    width: 12px;
    height: 12px;
    background-color: $red;
    border-radius: 50%;
    box-shadow: 0 0 0 0 $red;
    transform: scale(1);
    animation: pulse 1s infinite;
  }

  @media (max-width: 991px) {
    height: 87px;

    main {
      z-index: 3;
    }

    nav {
      justify-content: flex-end;
      margin-left: 0;

      ul {
        display: none;
      }

      > div {
        .user {
          display: none;
        }
      }
    }

    .menu-mobile {
      ul {
        margin-top: 87px;
      }
    }
  }

  @media (max-width: 450px) {
    height: 70px;

    main {
      z-index: 2;
      height: 70px;

      .logo {
        max-width: 130px;
      }
    }

    nav {
      > div {
        .btn-search {
          margin-right: 1rem;
        }
      }
    }

    .menu-mobile {
      ul {
        margin-top: 69px;
      }
    }
  }
}

.menu-button {
  z-index: 2;
  display: none;
  width: 2rem;
  cursor: pointer;

  @media (max-width: 991px) {
    display: block;
  }
}

.menu-icon {
  width: 2rem;
  height: 0.25rem;
  border-radius: 0.5rem;
  background-color: $white;
  display: block;
  position: relative;
  transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);

  &::before,
  &::after {
    content: '';
    width: 2rem;
    height: 0.25rem;
    display: block;
    border-radius: 0.5rem;
    background-color: $white;
    position: absolute;
    right: 0;
    transition: 0.2s all cubic-bezier(0.4, -0.1, 0, 1.1);
  }

  &::before {
    margin-top: -9px;
  }

  &::after {
    margin-top: 9px;
  }
}

.menu-opened {
  overflow: hidden;
  header {
    main {
      background: $dark-900;
    }

    .menu-mobile {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  .menu-icon {
    width: 2rem;
    height: 0;

    &::before {
      transform: rotate(-45deg);
      margin-top: 0;
    }

    &::after {
      transform: rotate(45deg);
      margin-top: 0;
    }
  }
}
</style>
