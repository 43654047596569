//./plugins/posthog.js
import posthog from 'posthog-js';

export default {
  install(app) {
    const $posthog = posthog.init(process.env.VUE_APP_POSTHOG_KEY, {
      api_host: process.env.VUE_APP_POSTHOG_INSTANCE_ADDRESS,
    });

    app.config.globalProperties.$posthog = $posthog;

    app.provide('$posthog', $posthog);
  },
};
