<template>
  <div :class="['newFeatureTag', size, color]">
    <span class="newFeatureTag__text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'FeatureTag',
  props: {
    text: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      required: true,
      validator: (prop) => ['medium', 'large'].includes(prop),
    },
    color: {
      type: String,
      default: 'spirit-stone',
      validator: (prop) => ['spirit-stone', 'dark-600'].includes(prop),
    },
  },
};
</script>

<style lang="scss" scoped>
.newFeatureTag {
  border-radius: 0.25rem;
  display: flex;

  &.medium {
    padding: 0.125rem 0.375rem;

    .newFeatureTag__text {
      font-size: 0.625rem;
    }
  }

  &.large {
    padding: 0.25rem 0.5rem;

    .newFeatureTag__text {
      font-size: 0.75rem;
    }
  }

  &.spirit-stone {
    background-color: $spirit-stone;

    .newFeatureTag__text {
      color: $dark-800;
    }
  }

  &.dark-600 {
    background-color: $dark-600;

    .newFeatureTag__text {
      color: $white;
    }
  }

  &__text {
    font-weight: 700;
  }
}
</style>
