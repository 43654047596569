import { captureError } from '@/Utils/Sentry/captureErrorSentry';
import lmsClient from '@/http/lms';

const ENDPOINT = '/ratings';

const state = {
  ratings: {
    course: {},
    lesson: {},
  },
};

const getters = {
  ratings: (state) => state.ratings,
  getRating: (state) => (ratingType, ratingReference) => state.ratings?.[ratingType]?.[ratingReference],
};

const mutations = {
  SET_USER_RATINGS(state, { ratingType, ratingValue, ratingReference }) {
    state.ratings[ratingType][ratingReference] = ratingValue;
  },
};

const actions = {
  async createUserRating({ commit }, payload) {
    try {
      commit('SET_USER_RATINGS', {
        ratingType: payload.ratingType,
        ratingValue: payload.ratingValue,
        ratingReference: payload.ratingReference,
      });

      const response = await lmsClient.post(ENDPOINT, {
        ratingReference: payload.ratingReference,
        ratingType: payload.ratingType,
        ratingKind: 'like',
        ratingValue: payload.ratingValue,
      });

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },
  async getUserRating({ commit }, ratingReference) {
    try {
      const response = await lmsClient.get(`${ENDPOINT}/${ratingReference}`);

      if (Object.keys(response.data.data || []).length) {
        commit('SET_USER_RATINGS', {
          ratingType: response.data.data.ratingType,
          ratingValue: response.data.data.ratingValue,
          ratingReference: response.data.data.ratingReference,
        });
      }

      return response.data;
    } catch (error) {
      console.error('## GPRIM ## error', error);
      captureError(error, 'error');
      return error?.response?.data;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
