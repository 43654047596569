/**
 * Module structure
 */
const state = {
  upsell: {
    showPage: false,
    userBought: false,
  },
};

const getters = {
  showUpsellPage: (state) => state.upsell.showPage,
  userBoughtUpsell: (state) => state.upsell.userBought,
};

const mutations = {
  SET_SHOW_UPSELL_PAGE(state, show) {
    state.upsell = {
      ...state.upsell,
      showPage: show,
    };
  },

  SET_USER_BOUGHT_UPSELL(state, bought) {
    state.upsell = {
      ...state.upsell,
      userBought: bought,
    };
  },
};

const actions = {
  setShowUpsellPage({ commit }, show) {
    commit('SET_SHOW_UPSELL_PAGE', show);
  },

  setUserBoughtUpsell({ commit }, show) {
    commit('SET_USER_BOUGHT_UPSELL', show);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
